import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../Modal";

function ViewUserShg() {
  const [shg, setSHG] = useState([]);
  const [shgUid, setShgUid] = useState("");
  const { mobile_no } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getmemberaccounts",
      { message: { access_token: token, mobile_no: mobile_no } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setSHG(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const myshg = Object.values(shg);

  const dataSHG = myshg.map((data, index) => {
    return (
      <tr key={index}>
        <td className="hidden lg:table-cell">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
          />
        </td>
        <td className="hidden lg:table-cell">
          <div className="flex flex-wrap flex-row items-center">
            <i className="fas fa-users"></i>
          </div>
        </td>
        <td>{data.mobile_no}</td>
        <td>{data.account_name} </td>
        <td className="hidden lg:table-cell">13/12/2022</td>
        <td className="hidden lg:table-cell">
          <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
            Active
          </span>
        </td>
        <td className="text-center">
          <button
            onClick={() => {
              setShgUid(data.uid);
              setIsModalOpen(true);
            }}
            className=" mx-3 text-sm px-2 py-1 font-semibold  text-white bg-red-500 rounded-md"
          >
            Unlink
          </button>
        </td>
      </tr>
    );
  });

  const unLinkUser = () => {
    // console.log(shgUid);
    Axios.post(
      "https://us-east4-safechama.cloudfunctions.net/unlinkmembertoaccount",
      { message: { access_token: token, mobile_no: mobile_no, uid: [shgUid] } }
    )
      .then((res) => {
        // console.log("Posting SHG Link Data From::", res.data.response);
        navigate("/affUserLinkShg");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <main className="pt-2 md:pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full md:px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                User's SHG
              </p>
              <div>
                <Modal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                >
                  <div className="p-8">
                    <div
                      className="bg-red-100 my-5    border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
                      role="alert"
                    >
                      <div className="flex">
                        <div className="py-1">
                          <svg
                            className="fill-current h-6 w-6 text-red-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold">Danger </p>
                          <p className="text-sm">
                            Are you sure you want To Unlink User
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <button
                        className="ml-auto rounded-sm text-white bg-rose-500 p-2"
                        onClick={() => {
                          unLinkUser();
                          setIsModalOpen(false);
                        }}
                      >
                        Yes Unlink
                      </button>
                      <button
                        className="ml-auto rounded-sm text-white bg-blue-500 p-2"
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="flex-shrink max-w-full md:px-4 w-full mb-6">
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="flex flex-wrap flex-row -mx-4">
                  <div className="flex-shrink max-w-full px-4 w-full">
                    <div className="w-full mb-6 overflow-x-auto">
                      <table className="table-sorter table-bordered w-full text-left text-gray-600 dark:text-gray-400">
                        <thead>
                          <tr className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-40">
                            <th
                              data-sortable="false"
                              className="hidden lg:table-cell"
                            >
                              <input
                                id="check_all"
                                type="checkbox"
                                className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                              />
                            </th>
                            <th className="hidden lg:table-cell">Image</th>
                            <th>User Mobile</th>
                            <th>SHG Name</th>

                            <th className="hidden lg:table-cell">Date added</th>
                            <th className="hidden lg:table-cell">Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{dataSHG}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ViewUserShg;
