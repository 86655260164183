import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

function Faq() {
  const [open, setOpen] = useState(false);
  const newDate = new Date();
  const year = newDate.getFullYear();

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div
        className={`fixed bottom-5 right-5 z-50 transition-opacity duration-300 ease-in ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <button
          className="md:px-4 md:py-3 px-3 py-2 hover:scale-105 rounded-full bg-orange-500 text-white shadow hover:bg-orange-700"
          onClick={scrollToTop}
        >
          <span>
            <i className="fas fa-arrow-up"></i>
          </span>
        </button>
      </div>
      <header className="md:py-4 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 sticky top-0 z-40">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="shrink-0">
              <img
                className="w-auto h-9"
                src={require("../img/logod.png")}
                alt=""
              />
            </div>

            <div className="flex md:hidden">
              <button onClick={handleOpen} type="button" className="text-white">
                <span aria-hidden="true" className={`${open && "hidden"} px-1`}>
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </span>

                <span
                  aria-hidden="true"
                  className={`${!open && "hidden"} px-1`}
                >
                  <span className="w-7 h-7">
                    <i className="fas fa-close"></i>
                  </span>
                </span>
              </button>
            </div>

            <nav className="hidden ml-10 mr-auto space-x-10 lg:ml-20 lg:space-x-12 md:flex md:items-center md:justify-start  ">
              <Link
                to="/"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>
            </nav>

            <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
              <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
              <Link
                to="/signIn"
                className="relative inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                role="button"
              >
                {" "}
                Sign In{" "}
              </Link>
            </div>
          </div>

          <nav className={`${!open && "hidden"} md:hidden`}>
            <div className="flex flex-col pt-8 pb-4 space-y-6">
              <Link
                to="/"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>
              <div className="relative inline-flex items-center justify-center group">
                <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                <Link
                  to="/signIn"
                  title=""
                  className="relative inline-flex items-center justify-center w-full px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                  role="button"
                >
                  {" "}
                  Sign In{" "}
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <main id="content" role="main">
        <div className="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
          <h1 className="text-2xl font-bold md:text-4xl dark:text-white">
            Frequently Asked Question
          </h1>
        </div>

        <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto ">
          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16 ">
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      What Is SaffeChama?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" rounded-lg bg-white px-6 py-6">
                    &nbsp;SaffeChama is a digital platform that enables savings
                    and credit for groups or chamas that are underbanked,
                    whether they are table banking, merry-go-round, or
                    investments; formal or informal. It helps them access
                    financial services that are not offered by the conventional
                    banking system.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      Why Is SaffeChama Important?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      o&nbsp;&nbsp;&nbsp; <strong>Financial Inclusion:</strong>{" "}
                      SaffeChama plays a crucial role in promoting financial
                      inclusion by providing access to financial services for
                      underbanked groups or chamas. Many of these groups, such
                      as table banking or merry-go-round groups, may not have
                      access to formal banking services. SaffeChama bridges this
                      gap by offering a digital platform specifically designed
                      to meet their needs.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; <strong>Savings and Credit:</strong>{" "}
                      SaffeChama enables these groups to save and access credit
                      easily. It allows members to contribute their savings
                      digitally, eliminating the need for physical cash handling
                      and enhancing transparency. Additionally, it provides a
                      mechanism for chamas to lend money to their members,
                      enabling access to credit that might otherwise be
                      unavailable to them.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Efficiency and Convenience:</strong> SaffeChama
                      streamlines the administrative processes involved in
                      managing chamas. It automates tasks such as
                      record-keeping, tracking contributions, and managing loan
                      transactions, saving time and effort for both the chama
                      members and the administrators. The digital platform also
                      offers convenience, allowing members to access their
                      savings and apply for loans using their smartphones or
                      computers.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Security and Accountability:</strong> By
                      digitizing the operations of chamas, SaffeChama enhances
                      security and accountability. It reduces the risk of fraud
                      or mismanagement of funds that may occur when cash
                      handling is involved. The platform provides a transparent
                      and auditable system, ensuring that members can trust the
                      financial transactions and records of their chamas.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; <strong>Economic Empowerment:</strong>{" "}
                      SaffeChama contributes to the economic empowerment of
                      individuals and communities. By facilitating savings and
                      credit, it supports the growth of small businesses,
                      investments, and entrepreneurial activities within chamas.
                      This, in turn, can lead to increased income, job creation,
                      and overall economic development.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Does Saffechama Works?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>&nbsp; Registration:</strong> Chamas can register
                      on the SaffeChama platform by providing necessary
                      information about the group and its members. This includes
                      details such as the group&#39;s name, purpose, member
                      names, and contact information.
                    </p>
                    <p>
                      2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Group Setup:</strong> Once registered, chamas can
                      set up their group structure within the platform. This
                      involves defining roles and permissions for different
                      members, such as administrators, treasurers, and regular
                      members. Group rules, contribution amounts, loan terms,
                      and other parameters can also be established.
                    </p>
                    <p>
                      3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Contribution Management:</strong> Members can
                      digitally contribute their savings to the chama through
                      the SaffeChama platform. This eliminates the need for
                      physical cash handling and provides a transparent record
                      of contributions. The platform tracks and records each
                      member&#39;s contributions, maintaining an accurate
                      account of their savings.
                    </p>
                    <p>
                      4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Loan Management:</strong> SaffeChama allows chamas
                      to provide loans to their members. Members can request
                      loans through the platform, specifying the loan amount and
                      purpose. The chama&#39;s administrators or designated
                      officers can review and approve these loan requests based
                      on the group&#39;s predefined criteria. Once approved, the
                      loan amount is disbursed to the member, and repayment
                      terms are established.
                    </p>
                    <p>
                      5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Communication and Notifications:</strong>{" "}
                      SaffeChama facilitates communication among chama members
                      and administrators. The platform enables members to stay
                      informed about group activities, such as contribution due
                      dates, loan approvals, and announcements. It also provides
                      notifications and reminders to ensure members are aware of
                      their financial obligations.
                    </p>
                    <p>
                      6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Reports and Analytics:</strong> SaffeChama
                      generates reports and analytics to help chama
                      administrators track the group&#39;s financial activities
                      and performance. These reports may include information
                      such as member savings, loan repayment status, outstanding
                      balances, and overall group financial health. This data
                      can be used to make informed decisions and manage the
                      chama effectively.
                    </p>
                    <p>
                      7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <strong>Security and Data Privacy:</strong> SaffeChama
                      prioritizes the security and privacy of chama data. The
                      platform implements measures to protect member information
                      and financial transactions from unauthorized access. This
                      includes encryption, secure login mechanisms, and
                      compliance with relevant data protection regulations.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Do I Access Saffechama?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      SaffeChama is accessible through the website and USSD
                      platform.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Do I Apply For A Loan?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      Once registered and loan limits assigned, the customer is
                      required to follow the application steps to access the
                      loan:
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Dial USSD code *617*31# on your mobile
                      phone.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Login to your account using your
                      mobile number and password.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Select any of your Self Help group.
                    </p>
                    <p>o&nbsp;&nbsp;&nbsp; Go to the loan Option</p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Select the loan request option to view
                      the limit, interest, and loan tenure.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Enter the loan amount and press OK to
                      continue
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Confirm loan details as displayed to
                      continue
                    </p>
                    <p>o&nbsp;&nbsp;&nbsp; Enter Mobile Money pin</p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Receive SMS notifications on Loan
                      allocation with relevant loan information.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Will My Loan Be Disbursed?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      Upon approval, the loan will be disbursed to the
                      borrower&rsquo;s mobile number through MPESA.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      What Happens If I Don&rsquo;t Pay On Time?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      If the loan is not repaid within the stipulated&nbsp;
                      days, the customer&rsquo;s credit rating is negatively
                      affected. However, if the loan is paid in full, the loan
                      contract is closed, and the customer can request another
                      loan. All transaction notifications to the customer will
                      be by SMS and Email.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      Can I Get More Than One Loan?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      o&nbsp;&nbsp;&nbsp; No. You can only get one loan at a
                      time and qualify for another loan if you Pay your loan in
                      full
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Qualified borrowers are eligible for
                      only one loan at a time hence multiple loan borrowing on
                      varied networks is therefore prohibited.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Can I Increase My Loan Limit?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      o&nbsp;&nbsp;&nbsp; Continue Saving with Saffechama to
                      your Self Help Groups and pay your loan on time
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Also, assessment of credit history on
                      subsequent loan applications and repayment shall apply to
                      determine subsequent qualifications for the facility.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Do I Know If I Am Eligible To Access SaffeChama?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>o&nbsp;&nbsp;&nbsp; Must be 18 years of age and above</p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Must have a valid national
                      Identification Card (ID)
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Must have a registered mobile number
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Have a mobile money account i.e.,
                      either Airtel Money/M-PESA/T-Kash
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; The SIM card in question can/ will
                      only be eligible if it has been in use for more than 90
                      days.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Can I Register An Account On The Platform?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>The customer is required to:</p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; You need to contact one of the
                      SaffeChama Affiliates who is responsible to register legal
                      Self Help Groups and their members.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; The affiliate will issue you with a
                      legal document, which you will be required to fill all of
                      the necessary details and provide the necessary documents
                      required such as Identification Cards (ID) etc.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Once the document is approved , your
                      new account will be created and you will be connected to
                      your desired Self Help Groups
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; With your new account, you can now
                      interact with the SaffeChama USSD platform and get to
                      enjoy all of the services available.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      What Happens If You Lose Your Mobile Phone And Have A
                      SaffeChama Account?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      o&nbsp;&nbsp;&nbsp; Your money is safe because your Mobile
                      Money PIN protects your SaffeChama account.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Once you replace your SIM card at your
                      Mobile Network Operator outlet, you will be able to access
                      your SaffeChama account using your current Mobile Money
                      PIN
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      What Are The Benefits Of Saving In SaffeChama?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      Customers will increase their loan limits as they continue
                      to save more and pay loans
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Can I Save Into SaffeChama?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      Deposit /sending directly to your savings from your Mobile
                      Money wallet
                    </p>
                    <p>o&nbsp;&nbsp;&nbsp; Customer will dial *617*31#</p>
                    <p>o&nbsp;&nbsp;&nbsp; Login to your account</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select any of you Self Help Group</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select the option for Savings</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select Deposit</p>
                    <p>o&nbsp;&nbsp;&nbsp; Enter Amount for Deposit</p>
                    <p>o&nbsp;&nbsp;&nbsp; Enter MPESA PIN for Confirmation</p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Done ,wait for the transaction to be
                      processed
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Do I Check My Savings Balance?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      <strong>Using USSD:</strong>
                    </p>
                    <p>o&nbsp;&nbsp;&nbsp; Customer will dial *617*31#</p>
                    <p>o&nbsp;&nbsp;&nbsp; Login to your account</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select any of you Self Help Group</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select the option for Savings</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select Option for &nbsp;Balance</p>
                    <p>o&nbsp;&nbsp;&nbsp; Select option for My Balance</p>
                    <p>o&nbsp;&nbsp;&nbsp; Done</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      Will I Get A Statement?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      o&nbsp;&nbsp;&nbsp; You will get a mini statement that
                      shows the recent few&nbsp; transactions
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; You can access the full Statement,
                      which will be sent to your Email.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      How Many Times Can You Deposit Money Into Your Savings
                      Account?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>You can do any number of deposits into your savings</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      What Is The Minimum And Maximum Amount That You Can
                      Deposit As Savings?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>You can deposit any amount into your savings</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      What Is The Loan Recovery &amp; Collection Management
                      Process?
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    <p>
                      This will involve monitoring and evaluation of the
                      facility disbursed and repaid to identify the level of
                      portfolio risks, the following initiatives will be adopted
                      for loan recovery and management.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; The borrower will be notified of the
                      due date and outstanding amount 5 days prior via SMS.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; On the thirtieth day of the facility,
                      the customer is notified that the facility is due.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; One day after the thirtieth day, the
                      borrower is notified that the facility is now overdue.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; After the due date, the borrower shall
                      not be eligible to apply for any other facility with the
                      Fund until the outstanding loan is fully paid.
                    </p>
                    <p>
                      o&nbsp;&nbsp;&nbsp; Overdue reminders shall be sent every
                      5 days up to a maximum of 30 days.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </main>

      <section className="py-4 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 sm:pt-6 lg:pt-6">
        <footer className="bg-white/10 rounded-lg shadow m-4 ">
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-100 sm:text-center ">
              © {year} All Rights Reserved, Msimbo DevOps.
            </span>
            <span className="text-sm flex items-center text-gray-100 sm:text-center ">
              <span className="mr-2">Powered by </span>
              <a href="https://sasapay.co.ke/" target="_blank" rel="noreferrer">
                <img
                  src={require("../img/sasapay.png")}
                  alt="lg"
                  className="w-24"
                />
              </a>
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-100  sm:mt-0">
              <li>
                <Link to="/" className="mr-4 hover:underline  ">
                  <span className="mr-1">Home</span> |
                </Link>
              </li>

              <li>
                <Link to="/faq" className="mr-4 hover:underline  ">
                  <span className="mr-1">FAQs</span> |
                </Link>
              </li>

              <li>
                <Link className="mr-4 hover:underline" to="/terms">
                  <span className="mr-1">Terms and Conditions</span>|
                </Link>
              </li>
              <li>
                <Link className=" mr-4 hover:underline" to="/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </section>
    </>
  );
}

export default Faq;
