import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [totalSHG, setTotalSHG] = useState([]);
  const [totalSavings, setTotalSavings] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const token = localStorage.getItem("userToken");

  const [totalLoans, setTotalLoans] = useState();
  const [totalAffiliates, setTotalAffiliates] = useState();
  const [totalPaidLoans, setTotalPaidLoans] = useState();
  const [totalUnPaidLoans, setTotalUnPaidLoans] = useState();
  const [totalLoanInterest, setTotalLoanInterest] = useState();

  useEffect(() => {
    Axios.post(
      " https://us-central1-safechama.cloudfunctions.net/statistics",
      { message: { access_token: token, option: "noofSHG" } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setTotalSHG(res.data.data);
      })
      .catch((err) => console.log(err));

    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getallloans",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta All Loans From::", res.data.data);
        const myTotalLoans = Object.values(res.data.data);

        const totalLoanss = myTotalLoans.length;
        setTotalLoans(totalLoanss);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Axios.post(
      " https://us-central1-safechama.cloudfunctions.net/gettotalinterset",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta Total Interest From::", res.data.total_amount);
        setTotalLoanInterest(res.data.total_amount);
      })
      .catch((err) => console.log(err));

    Axios.post(
      " https://us-central1-safechama.cloudfunctions.net/gettotalpaidloans",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log(
        //   "GEtting DAta Total Paid Loans From::",
        //   res.data.total_amount
        // );
        setTotalPaidLoans(res.data.total_amount);
      })
      .catch((err) => console.log(err));

    Axios.post(
      " https://us-central1-safechama.cloudfunctions.net/gettotalunpaidloans",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta Total UnPaid Loans From::", res.data.total_amount);
        setTotalUnPaidLoans(res.data.total_amount);
      })
      .catch((err) => console.log(err));

    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getalluserinfo",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        const affiliatess = res.data.data;
        const data = Object.values(affiliatess);

        const affiliates = data.filter((person) => person.role === "affiliate");
        const affiliateCount = affiliates.length;
        setTotalAffiliates(affiliateCount);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/statistics",
      { message: { access_token: token, option: "noofusers" } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From No of users::", res.data);
        setTotalUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/statistics",
      { message: { access_token: token, option: "totalsavings" } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From total savings::", res);

        const responseData = res.data.data;

        // Check if responseData is NaN, and if so, set it to 0
        const totalSavingsValue = isNaN(responseData) ? 0 : responseData;

        setTotalSavings(totalSavingsValue);
        // setTotalSavings(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="w-full px-3 md:px-6 py-6 mx-auto">
        <div className="grid grid-cols-12 gap-5 mb-5">
          <div className="2xl:col-span-3 lg:col-span-4 col-span-12">
            <div className="bg-no-repeat bg-cover bg-center p-4 rounded-[6px] relative bggg">
              <div className="max-w-[180px]">
                <div className="text-xl font-medium text-slate-900 mb-2">
                  Welcome To Admin Dashboard
                </div>
                <p className="text-sm text-slate-800">
                  Here are your Statistics!
                </p>
              </div>
            </div>
          </div>
          <div className="2xl:col-span-9 lg:col-span-8 col-span-12">
            <div className="p-4 bg-white dark:bg-gray-600 rounded-md">
              <div className="grid md:grid-cols-3 col-span-1 gap-4">
                <div className="py-[18px] px-4 rounded-[6px] bg-[#E5F9FF] dark:bg-slate-900	 ">
                  <div className="flex items-center space-x-6 rtl:space-x-reverse">
                    <div className="flex-none">
                      <div id="wline1"></div>
                    </div>
                    <div className="flex-1">
                      <div className="text-slate-800 dark:text-slate-300 text-sm mb-1 font-medium">
                        Total Revenue
                      </div>
                      <div className="text-slate-900 dark:text-white text-lg font-medium">
                        KES {totalSavings}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-[18px] px-4 rounded-[6px] bg-[#FFEDE5] dark:bg-slate-900	 ">
                  <div className="flex items-center space-x-6 rtl:space-x-reverse">
                    <div className="flex-none">
                      <div id="wline2"></div>
                    </div>
                    <div className="flex-1">
                      <div className="text-slate-800 dark:text-slate-300 text-sm mb-1 font-medium">
                        All Users
                      </div>
                      <div className="text-slate-900 dark:text-white text-lg font-medium">
                        {totalUsers} Members
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-[18px] px-4 rounded-[6px] bg-[#EAE5FF] dark:bg-slate-900	 ">
                  <div className="flex items-center space-x-6 rtl:space-x-reverse">
                    <div className="flex-none">
                      <div id="wline3"></div>
                    </div>
                    <div className="flex-1">
                      <div className="text-slate-800 dark:text-slate-300 text-sm mb-1 font-medium">
                        Growth
                      </div>
                      <div className="text-slate-900 dark:text-white text-lg font-medium">
                        +5.0%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className="mb-4 font-sans text-xl font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
          Statistics
        </h1>

        <div className="flex flex-wrap  -mx-3">
          <div className="w-full max-w-full  px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/sgh"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total SHGs
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalSHG}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> No.
                          </span>
                          <br />
                          SHGs
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className=" leading-none fas fa-users text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full max-w-full  px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/user"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total Affiliates
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalAffiliates}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> No.
                          </span>
                          <br />
                          Affiliates
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-cyan-500 to-blue-500">
                        <i className="fas fa-user-shield leading-none  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full max-w-full  px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/shgSavings"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total Savings
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalSavings}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> KES
                          </span>
                          <br />
                          Savings
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-gray-500 to-yellow-500">
                        <i className="fas fa-piggy-bank leading-none  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full max-w-full   px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/user"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total Members
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalUsers}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> No.
                          </span>
                          <br />
                          Users
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                        <i className="fas fa-user leading-none  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full max-w-full my-3 px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/allLoans"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total Loans
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalLoans}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> No.
                          </span>
                          <br />
                          Loans
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-yellow-500 to-green-500">
                        <i className="fas fa-hand-holding-usd leading-none  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full max-w-full my-3 px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/paidLoans"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total Paid Loans
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalPaidLoans}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> KES.
                          </span>
                          <br />
                          Loans
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-pink-500 to-orange-500">
                        <i className="fas fa-sack-dollar leading-none  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="w-full max-w-full my-3 px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/unpaidLoans"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total UnPaid Loans
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {totalUnPaidLoans}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> KES.
                          </span>
                          <br />
                          Unpaid Loans
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-cyan-500 to-blue-500">
                        <i className="fas fa-user-shield leading-none  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full max-w-full my-3 px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/4 ">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                        Total Loans Interest
                      </p>
                      <h5 className="mb-2 font-bold text-4xl dark:text-white">
                        <span className="opacity-0">0</span>
                        {totalLoanInterest}
                      </h5>
                      <p className="mb-2 dark:text-white dark:opacity-60">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          <i className="fas fa-arrow-right"></i> KES.
                        </span>
                        <br />
                        Loans Interest
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3">
                    <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-purple-500 to-indigo-500">
                      <i className="fas fa-user-shield leading-none  text-lg relative top-3.5 text-white"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
