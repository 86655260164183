import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import { DownloadTableExcel } from "react-export-table-to-excel";

function AffShgView() {
  const { accountname } = useParams();
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("userToken");
  const shgRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getallmembersinSHG",
      { message: { access_token: token, account_name: accountname } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting Users Data ::", res.data.data);
        setUsers(res.data.data);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const myuser = Object.values(users);
  const dataUsers = myuser.map((data, index) => {
    return (
      <tr
        key={index}
        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
      >
        <td className="border-grey-light border hover:bg-gray-100 p-3">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
          />
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <div className="flex flex-wrap flex-row items-center">
            <img
              className="h-8 w-8 rounded-full"
              src={require("../../img/avatar8.png")}
              alt="profile"
            />
          </div>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/affShgMemberView/${data.mobile_no}`}>{data.name}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {" "}
          <Link to={`/affShgMemberView/${data.mobile_no}`}>{data.role}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/affShgMemberView/${data.mobile_no}`}>
            {data.mobile_no}
          </Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/affShgMemberView/${data.mobile_no}`}>
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
              Active
            </span>
          </Link>
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/affShgMemberView/${data.mobile_no}`}>
            <button
              type="button"
              class="hidden my-2 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
            >
              View{" "}
            </button>
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <>
      <main className="pt-6 md:pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full md:px-4 w-full mb-6">
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="flex flex-wrap flex-row -mx-4">
                  <div className="flex-shrink max-w-full px-4 w-full">
                    <div className="w-full mb-6 overflow-x-auto">
                      <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                        <div class="flex items-center justify-between">
                          <div>
                            <h4 class="dark:text-white font-semibold my-3">
                              {accountname} Members
                            </h4>
                            <Link
                              className="mt-4"
                              to={`/affCreateOfficial/${accountname}`}
                            >
                              <button
                                type="button"
                                class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                              >
                                Add Members +
                              </button>
                            </Link>
                          </div>

                          <DownloadTableExcel
                            filename="SHGs"
                            sheet="shgs"
                            currentTableRef={shgRef.current}
                          >
                            <button
                              type="button"
                              class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                            >
                              Export Excel <i className="fas fa-download"></i>
                            </button>
                          </DownloadTableExcel>
                        </div>
                      </div>
                      <table
                        className="table-sorter table-bordered w-full text-left text-gray-600 dark:text-gray-400"
                        ref={shgRef}
                      >
                        <thead>
                          <tr className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-40">
                            <th
                              data-sortable="false"
                              className="hidden lg:table-cell"
                            >
                              <input
                                id="check_all"
                                type="checkbox"
                                className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                              />
                            </th>
                            <th className="hidden lg:table-cell">Image</th>
                            <th className="hidden lg:table-cell">Username</th>
                            <th className="hidden lg:table-cell">Role</th>
                            <th className="hidden lg:table-cell">Contacts</th>
                            <th className="hidden lg:table-cell">Status</th>
                            <th
                              className="hidden lg:table-cell"
                              data-sortable="false"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            dataUsers
                          ) : (
                            <tr className="flex justify-center  items-center h-40 w-full">
                              <td className="w-full mx-auto ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="50"
                                  fill="currentColor"
                                  className="bi bi-arrow-repeat animate-spin"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                  <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                </svg>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AffShgView;
