import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function TresDashboard() {
  const tresSHG = localStorage.getItem("userSHG");
  const token = localStorage.getItem("userToken");

  const navigate = useNavigate();

  useEffect(() => {
    checkSHG();
  }, []);

  const checkSHG = () => {
    if (!localStorage.getItem("userSHG")) {
      navigate("/tresSelectSHG");
    }
  };

  const [savings, setSavings] = useState([]);
  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getallsavings",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.grouped_savings);
        setSavings(res.data.grouped_savings);
      })
      .catch((err) => console.log(err));
  }, []);

  const mySavings = Object.values(savings);
  const dataSavings = mySavings.map((data, index) => {
    if (data.account_name == tresSHG) {
      return <span key={index}>{data.amount}</span>;
    }
  });

  return (
    <>
      <div className="w-full px-3 md:px-6 py-6 mx-auto">
        <div class="grid grid-cols-12 gap-5 mb-5">
          <div class="2xl:col-span-3 lg:col-span-4 col-span-12">
            <div class="bg-no-repeat bg-cover bg-center p-4 rounded-[6px] relative bggg">
              <div class="max-w-[180px]">
                <div class="text-xl font-medium text-slate-900 mb-2">
                  Welcome Treasurer Dashboard
                </div>
                <p class="text-sm text-slate-800">Here are your Statistics!</p>
              </div>
            </div>
          </div>
          <div class="2xl:col-span-9 lg:col-span-8 col-span-12">
            <div class="p-4 bg-white dark:bg-gray-600 rounded-md">
              <div class="grid md:grid-cols-2 col-span-1 gap-4">
                <div class="py-[18px] px-4 rounded-[6px] bg-[#E5F9FF] dark:bg-slate-900	 ">
                  <div class="flex items-center space-x-6 rtl:space-x-reverse">
                    <div class="flex-none">
                      <div id="wline1"></div>
                    </div>
                    <div class="flex-1">
                      <div class="text-slate-800 dark:text-slate-300 text-sm mb-1 font-medium">
                        Total Savings
                      </div>
                      <div class="text-slate-900 dark:text-white text-lg font-medium">
                        KES {dataSavings}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="py-[18px] px-4 rounded-[6px] bg-[#EAE5FF] dark:bg-slate-900	 ">
                  <div class="flex items-center space-x-6 rtl:space-x-reverse">
                    <div class="flex-none">
                      <div id="wline3"></div>
                    </div>
                    <div class="flex-1">
                      <div class="text-slate-800 dark:text-slate-300 text-sm mb-1 font-medium">
                        Growth
                      </div>
                      <div class="text-slate-900 dark:text-white text-lg font-medium">
                        +5.0%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className="mb-4 font-sans text-xl font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
          Statistics
        </h1>
        <div className="flex flex-wrap  -mx-3">
          <div className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/3">
            <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <Link to={"/tresShgSavings"}>
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                          Total Group Savings
                        </p>
                        <h5 className="mb-2 font-bold text-4xl dark:text-white">
                          <span className="opacity-0">0</span>
                          {dataSavings}
                        </h5>
                        <p className="mb-2 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            <i className="fas fa-arrow-right"></i> KES
                          </span>
                          <br />
                          Transactions
                        </p>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className=" leading-none fas fa-piggy-bank text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TresDashboard;
