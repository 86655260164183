import React, { useContext, useEffect, useRef, useState } from "react";
import { authContext } from "./Context/AuthContext";
import { sidebarData } from "./data";
import Submenu from "./Submenu";
import { ChairData } from "./pages/chairman/Chairdata";
import { SecData } from "./pages/secretary/SecData";
import { TresData } from "./pages/treasurer/TresData";
import { AffData } from "./pages/affiliate/AffData";
import { UserData } from "./pages/user/UserData";
import { Link, useNavigate } from "react-router-dom";

const SidebarWithSubmenu = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen((state) => !state);
  const { logged, setLogged } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserID } = useContext(authContext);
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current === false) {
      checkLogin();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem("userMobile")) {
      setLogged(true);
    } else {
      setLogged(false);
      // navigate("/signIn");
    }
  };

  // console.log(logged);
  const handleLogout = () => {
    localStorage.removeItem("userMobile");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userSHG");
    localStorage.removeItem("userToken");
    setLogged(false);
    setUserName(undefined);
    setUserMobile(undefined);
    setUserID(undefined);
    navigate("/signIn");
  };

  return (
    <>
      <div className={`${!logged && "hidden"}`}>
        <div className="lg:hidden fixed top-2 left-4 z-50">
          <span
            onClick={toggleSidebar}
            className="inline-flex w-12 h-12 items-center justify-center cursor-pointer rounded-full bg-slate-200 hover:bg-slate-400"
          >
            <i className="fa-solid fa-bars"></i>
          </span>
        </div>

        {/* sidebar */}
        <div
          className={` ${
            isSidebarOpen
              ? "lg:left-0 lg:translate-x-0 "
              : "-translate-x-full md:left-0 lg:translate-x-0"
          } fixed inset-y-0 flex-wrap items-center justify-between block  p-0 my-4 overflow-y-auto antialiased transition-transform duration-200  bg-white border-0 shadow-xl dark:shadow-none dark:bg-gray-700 w-64 ease-nav-brand z-990 md:ml-6 rounded-2xl`}
        >
          <div className="h-19">
            <i
              className="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times dark:text-white text-slate-400 lg:hidden"
              onClick={toggleSidebar}
            ></i>
            <a
              className="block px-8 py-6 m-0 text-sm whitespace-nowrap dark:text-white text-slate-700"
              href="index.html"
              target="_blank"
            >
              <span className="ml-1 uppercase font-semibold transition-all duration-200 ease-nav-brand">
                {userRole} Dashboard
              </span>
            </a>
          </div>

          <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

          <div className="items-center block w-auto   overflow-auto  grow basis-full">
            <ul>
              {userRole === "admin"
                ? sidebarData.map((item, i) => <Submenu item={item} key={i} />)
                : userRole === "chairman"
                ? ChairData.map((item, i) => <Submenu item={item} key={i} />)
                : userRole === "secretary"
                ? SecData.map((item, i) => <Submenu item={item} key={i} />)
                : userRole === "treasurer"
                ? TresData.map((item, i) => <Submenu item={item} key={i} />)
                : userRole === "affiliate"
                ? AffData.map((item, i) => <Submenu item={item} key={i} />)
                : userRole === "user"
                ? UserData.map((item, i) => <Submenu item={item} key={i} />)
                : " Loading ... "}

              {}

              <li className="w-full mt-4">
                <h6 className="pl-6 ml-2 text-xs font-bold leading-tight uppercase dark:text-white opacity-60">
                  User Account
                </h6>
              </li>

              <li className="mt-0.5 w-full">
                {userRole === "admin" ? (
                  <Link
                    to="./profile"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                ) : userRole === "chairman" ? (
                  <Link
                    to="./chairProfile"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                ) : userRole === "secretary" ? (
                  <Link
                    to="./secProfile"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                ) : userRole === "treasurer" ? (
                  <Link
                    to="./tresProfile"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                ) : userRole === "affiliate" ? (
                  <Link
                    to="./affProfile"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                ) : userRole === "user" ? (
                  <Link
                    to="./userProfile"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                ) : (
                  <Link
                    to="/"
                    className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  >
                    <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                      <i className="relative top-0  leading-normal text-slate-700 dark:text-gray-200 fas fa-user-shield"></i>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                      Profile
                    </span>
                  </Link>
                )}
              </li>

              <li className="mt-0.5 w-full">
                <div
                  className=" cursor-pointer dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
                  onClick={handleLogout}
                >
                  <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <i className="relative top-0  leading-normal text-orange-500 fas fa-sign-out-alt"></i>
                  </div>
                  <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
                    Log Out
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarWithSubmenu;
