import { createContext } from "react";

export const authContext = createContext({});

// Axios.post(
//     "https://us-central1-safechama.cloudfunctions.net/basicauth",
//     { message: { mobile_no: mobile_no, password: lpassword } },
//     {
//       headers: { "Content-Type": "application/json" },
//     },
//     { withCredentials: true }
//   )

//   Axios.post(
//     "https://us-east4-safechama.cloudfunctions.net/Login",
//     {
//       message: {
//         access_token: `${res.data.access_token}=`,
//         mobile_no: mobile_no,
//         password: lpassword,
//       },
//     },
//     {
//       headers: { "Content-Type": "application/json" },
//     }
//   )

// localStorage.setItem("userToken", res.data.access_token);

// Posting Data From::
// {code: 404, response: 'Invalid access token'}
// code
// :
// 404
// response
// :
// "Invalid access token"
