import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Login from "../login/Login";

const Protected = (props) => {
  const userMobile = localStorage.getItem("userMobile");

  if (userMobile) {
    const user = localStorage.getItem("userRole");

    // if (props.roles === user) {
    //   <Outlet />;
    // } else {
    //   <Navigate to="/unauthorized" replace />;
    // }

    return props.roles === user ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  } else {
    return <Login />;
  }
};

export default Protected;
