import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { authContext } from "../../Context/AuthContext";
const token = localStorage.getItem("userToken");

function AffCreateShg() {
  const navigate = useNavigate();

  const { userName } = useContext(authContext);
  const { userMobile } = useContext(authContext);

  const [accountname, setAccountName] = useState("");
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const createSHG = (e) => {
    e.preventDefault();

    if (accountname) {
      Axios.post(
        "https://us-east4-safechama.cloudfunctions.net/createSelfHelpGroupAccount",
        {
          message: {
            access_token: token,
            accountname: accountname,
            mobile_no: userMobile,
            created_by: userName,
            date_created: date,
          },
        }
      )
        .then((res) => {
          if (res.data.code === 500) {
            alert(res.data.response);
            navigate("/affShg");
          } else if (res.data.code === 606) {
            setuserErr(true);
          }
          // console.log("Posting Data From::", res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setallErr(true);
    }
  };

  return (
    <>
      <main className="pt-2 md:pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full md:px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                Add New SHG
              </p>
            </div>
            <div className="flex-shrink mx-auto max-w-full md:px-4 w-full lg:w-2/3 mb-6">
              <div
                className={`${
                  !allErr && "hidden"
                } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-orange-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Creating SGH
                    </p>
                    <p className="text-sm">* All Fields are Required</p>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  !userErr && "hidden"
                } bg-yellow-100 my-5    border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-yellow-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Creating SGH
                    </p>
                    <p className="text-sm">SGH Exists</p>
                  </div>
                </div>
              </div>
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
                <div className="flex-1 p-6 bg-gray-100 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <form className="flex flex-wrap flex-row -mx-4">
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        SHG Name
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={accountname}
                        onChange={(e) => {
                          setAccountName(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full">
                      <button
                        type="submit"
                        onClick={createSHG}
                        className=" text-center mb-3 rounded py-2 px-5 inline-block   leading-5 text-gray-100 bg-indigo-500 bg-transparent border border-indigo-500 hover:text-gray-100 hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"
                      >
                        Add SGH +
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AffCreateShg;
