import React from "react";
import Navbar from "./Navbar";
import SidebarWithSubmenu from "./SidebarWithSubmenu";
import { Link } from "react-router-dom";

const Layout = ({ children }) => {
  const currentYear = new Date().getFullYear();
  if (localStorage.getItem("userMobile")) {
    return (
      <>
        <div className="">
          <Navbar />

          <div className="lg:flex flex-wrap">
            <div className="w-full lg:w-[25%]">
              <SidebarWithSubmenu />
            </div>
            <div className="w-full lg:w-[75%] min-h-[91vh]">
              {children}
              <footer className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 rounded-lg shadow m-4  md:mt-24">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                  <span className="text-sm text-gray-100 sm:text-center ">
                    © {currentYear} All Rights Reserved, Msimbo DevOps.
                  </span>
                  <span className="text-sm flex items-center text-gray-100 sm:text-center ">
                    <img
                      src={require("./img/msimbo.PNG")}
                      alt="lg"
                      className="w-16"
                    />
                  </span>
                  <span className="text-sm flex items-center text-gray-100 sm:text-center ">
                    <span className="mr-2">Powered by </span>
                    <a
                      href="https://sasapay.co.ke/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require("./img/sasapay.png")}
                        alt="lg"
                        className="w-24"
                      />
                    </a>
                  </span>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="w-full ">{children}</div>
      </>
    );
  }
};

export default Layout;
