import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../Context/AuthContext";

import { Disclosure } from "@headlessui/react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";

function Home() {
  const [formUrl] = useState(
    "https://forms.zohopublic.com/abnyabera/form/ClientDetails/formperma/FDtx4MK6fAgsSrVRg1te20Xnuv8X-_3qr4IK5aqVnlg"
  );
  const [formWidth] = useState(700);
  const [formHeight] = useState(648);

  const openFormWindow = () => {
    const leftPos = (window.screen.width - formWidth) / 2;
    const topPos = (window.screen.height - formHeight) / 2;
    const windowFeatures = `width=${formWidth},height=${formHeight},left=${leftPos},top=${topPos},toolbar=0,location=0,status=1,scrollbars=1,resizable=1`;

    window.open(formUrl, null, windowFeatures);
  };
  const { setLogged } = useContext(authContext);
  const newDate = new Date();
  const year = newDate.getFullYear();
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem("userMobile")) {
      setLogged(true);
      const useRole = localStorage.getItem("userRole");

      if (useRole === "admin") {
        navigate("/dashboard");
      } else if (useRole === "chairman") {
        navigate("/chairDashboard");
      } else if (useRole === "secretary") {
        navigate("/secDashboard");
      } else if (useRole === "treasurer") {
        navigate("/tresDashboard");
      } else if (useRole === "affiliate") {
        navigate("/affDashboard");
      } else if (useRole === "user") {
        navigate("/userDashboard");
      }
    } else {
      navigate("/");
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={`fixed bottom-5 right-5 z-50 transition-opacity duration-300 ease-in ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <button
          className="md:px-4 md:py-3 px-3 py-2 hover:scale-105 rounded-full bg-orange-500 text-white shadow hover:bg-orange-700"
          onClick={scrollToTop}
        >
          <span>
            <i className="fas fa-arrow-up"></i>
          </span>
        </button>
      </div>
      <header className="md:py-4 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900  sticky top-0 z-40">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="shrink-0">
              <span className="flex">
                <img
                  className="w-auto h-9"
                  src={require("../img/logod.png")}
                  alt=""
                />
              </span>
            </div>

            <div className="flex md:hidden">
              <button
                onClick={handleOpen}
                type="button"
                className="text-white"
                aria-expanded="expanded"
              >
                <span aria-hidden="true" className={`${open && "hidden"} px-1`}>
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </span>

                <span
                  aria-hidden="true"
                  className={`${!open && "hidden"} px-1`}
                >
                  <span className="w-7 h-7">
                    <i className="fas fa-close"></i>
                  </span>
                </span>
              </button>
            </div>

            <nav className="hidden ml-10 mr-auto space-x-10 lg:ml-20 lg:space-x-12 md:flex md:items-center md:justify-start  ">
              <a
                href="#home"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </a>

              <a
                href="#features"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Features{" "}
              </a>

              <a
                href="#work"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                How it Works{" "}
              </a>

              <Link
                to="/faq"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Faq{" "}
              </Link>
            </nav>

            <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
              <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
              <Link
                to="/signIn"
                className="relative inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                role="button"
              >
                {" "}
                Sign In{" "}
              </Link>
            </div>
          </div>

          <nav className={`${!open && "hidden"} md:hidden`}>
            <div className="flex flex-col pt-8 pb-4 space-y-6">
              <a
                href="#home"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </a>

              <a
                href="#features"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Features{" "}
              </a>

              <a
                href="#work"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                How it Works{" "}
              </a>

              <Link
                to="/faq"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Faq{" "}
              </Link>

              <div className="relative inline-flex items-center justify-center group">
                <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                <Link
                  to="/signIn"
                  className="relative inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                  role="button"
                >
                  {" "}
                  Sign In{" "}
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div
        className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900"
        id="home"
      >
        <section className="relative py-12 overflow-hidden  ">
          <div className="px-4 mx-auto relativea sm:px-6 lg:px-8 max-w-7xl">
            <div className="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 gap-x-16">
              <div>
                <h1 className="text-2xl font-normal text-white sm:text-5xl lg:text-6xl ">
                  Digitizing the ‘underbanked’ Chamas through USSD *617*31#
                </h1>
                <p className="mt-4 md:text-lg  font-normal text-gray-300 sm:mt-8">
                  SaffeChama is a digital platform that enables savings and
                  credit for groups or chamas that are underbanked, whether they
                  are table banking, merry-go-round, or investments; formal or
                  informal. It helps them access financial services that are not
                  offered by the conventional banking system.
                </p>

                <div className="mt-5 relative hidden md:items-center md:justify-center md:inline-flex group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <a
                    href="#contact"
                    title=""
                    className="relative inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                    role="button"
                  >
                    {" "}
                    Contact Us{" "}
                  </a>
                </div>
              </div>

              <div className="relative">
                <img
                  className=" w-full  mx-auto"
                  src={require("../img/safechamauusd.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 pt-5">
        {/* About us Video */}

        <div className="relative overflow-hidden" id="features">
          <div className="max-w-[85rem] mx-auto bg-player">
            <div className="px-4 sm:px-6 lg:px-8 py-10 bg-white/10 rounded-xl">
              <div className="mt-10 relative max-w-5xl mx-auto">
                <div className="w-full bg-video object-cover h-full  bg-no-repeat bg-center bg-cover rounded-xl"></div>

                <div>
                  <Plyr
                    // https://p-def6.pcloud.com/cBZz6QOBtZU4GFHk7ZZZ0vgHykZQ5ZZXAkZkZp95oZUzZeLZdRZT7ZYLZrLZDHZz4ZWLZCLZYpZLRZEZ24ZlE6NVZaMVdRIzVXQ4h5Oa6LmqKGm3kj6J7/safechama.mp4
                    // source={{
                    //   type: "video",
                    //   // @ts-ignore
                    //   sources: [
                    //     {
                    //       src: "https://vortesnail.github.io/qier-player-demo/static/media/video480p.d116ba09.mp4",
                    //       provider: "html5",
                    //     },
                    //   ],
                    // }}
                    source={{
                      type: "video",
                      // @ts-ignore
                      sources: [
                        {
                          src: "https://k254nairobinews.com/safechamas.mp4",
                          provider: "html5",
                        },
                      ],
                    }}
                  />
                </div>

                <div className="absolute bottom-12 -left-20 -z-[1] w-48 h-48 bg-gradient-to-b from-orange-500  p-px rounded-lg to-slate-900">
                  <div className=" w-48 h-48 rounded-lg bg-slate-900"></div>
                </div>

                <div className="absolute -top-12 -right-20 -z-[1] w-48 h-48 bg-gradient-to-t from-blue-600 to-cyan-400 p-px rounded-full">
                  <div className=" w-48 h-48 rounded-full bg-slate-900"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className=" mx-auto max-w-7xl   overflow-hidden relative">
          <img
            className=" w-full rounded-md  mx-auto "
            src={require("../img/baner.jpg")}
            alt=""
          />
          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 opacity-0 transition duration-300 ease-in-out hover:opacity-70"></div>
        </div> */}
      </section>

      <section
        className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 py-5"
        id="features"
      >
        <div className="px-4 mx-auto   sm:px-6 lg:px-8">
          <h2 className=" text-3xl text-center pt-6 font-normal leading-tight text-white sm:text-4xl lg:text-5xl">
            SaffeChama Features
          </h2>
          <div className="grid  items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-7 sm:mt-20 gap-x-4">
            <div className="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12 ">
              <div className="flex items-start">
                <div className="relative bg-white/10 py-6 px-6 rounded-3xl  my-4 shadow-xl hover:bg-white/20">
                  <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-pink-500 left-4 -top-6">
                    <i className="fas fa-globe"></i>
                  </div>
                  <div className="mt-8">
                    <p className="text-xl font-semibold my-2 text-white">
                      Web View
                    </p>

                    <div className="border-t-2"></div>

                    <p className="mt-3 text-base text-gray-300">
                      Chama officials(Chairperson,Secretary, Treasuree) have a
                      web view where they can run the affairs of the chama and
                      issue reports.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-start">
                <div className="relative bg-white/10 py-6 px-6 rounded-3xl  my-4 shadow-xl hover:bg-white/20">
                  <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-green-500 left-4 -top-6">
                    <i className="fas fa-sack-dollar"></i>
                  </div>
                  <div className="mt-8">
                    <p className="text-xl font-semibold my-2 text-white">
                      Balance
                    </p>

                    <div className="border-t-2"></div>

                    <p className="mt-3 text-base text-gray-300">
                      Chama members can query their balance at anytime to know
                      their individual and group performance.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-start">
                <div className="relative bg-white/10 py-6 px-6 rounded-3xl  my-4 shadow-xl hover:bg-white/20">
                  <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-blue-500 left-4 -top-6">
                    <i className="fas fa-phone"></i>
                  </div>
                  <div className="mt-8">
                    <p className="text-xl font-semibold my-2 text-white">
                      USSD
                    </p>

                    <div className="border-t-2"></div>

                    <p className="mt-3 text-base text-gray-300">
                      Chama members without being limited to type of phone can
                      access SaffeChama services via USSD menus
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:col-span-3">
              <img
                className="w-full rounded-lg "
                src={require("../img/ussd/dashboard.png")}
                alt=""
              />
            </div>

            <div className="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12 ">
              <div className="flex items-start">
                <div className="relative bg-white/10 py-6 px-6 rounded-3xl  my-4 shadow-xl hover:bg-white/20">
                  <div className=" text-black flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-white left-4 -top-6">
                    <i className="fas fa-comments"></i>
                  </div>
                  <div className="mt-8">
                    <p className="text-xl font-semibold my-2 text-white">
                      {" "}
                      Statements
                    </p>

                    <div className="border-t-2"></div>

                    <p className="mt-3 text-base text-gray-300">
                      Chama members can extract their contributions statements
                      via SMS or Email
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-start">
                <div className="relative bg-white/10 py-6 px-6 rounded-3xl  my-4 shadow-xl hover:bg-white/20">
                  <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-cyan-500 left-4 -top-6">
                    <i className="fas fa-piggy-bank"></i>
                  </div>
                  <div className="mt-8">
                    <p className="text-xl font-semibold my-2 text-white">
                      {" "}
                      Savings
                    </p>

                    <div className="border-t-2"></div>

                    <p className="mt-3 text-base text-gray-300">
                      Chama members can contribute to their savings and chama
                      savings via Mpesa from anywhere
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-start">
                <div className="relative bg-white/10 py-6 px-6 rounded-3xl  my-4 shadow-xl hover:bg-white/20">
                  <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-orange-500 left-4 -top-6">
                    <i className="fas fa-hand-holding-usd"></i>
                  </div>
                  <div className="mt-8">
                    <p className="text-xl font-semibold my-2 text-white">
                      {" "}
                      Loans
                    </p>

                    <div className="border-t-2"></div>

                    <p className="mt-3 text-base text-gray-300">
                      Saffechama gives members limited loan limits based on
                      their interaction with the system at affordable rates of
                      2.5% interest on reducing balance
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h4 className="text-xl font-medium text-gray-900">
              Numbers tell the hard works we’ve done
            </h4>
          </div>

          <div className="grid grid-cols-1 gap-6 md:px-6 mt-8 sm:px-0 lg:mt-16 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-12">
            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">20k+</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      Active members
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">1,574</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      Chamas registered
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">98%</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      Customer success
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <img
            className="mt-8 w-full  mx-auto"
            src={require("../img/dashboard.PNG")}
            alt=""
          />
        </div>
      </section>

      <section className="py-10 bg-white sm:py-16 lg:py-24" id="work">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              How does it work?
            </h2>
          </div>

          <div className="relative mt-12 lg:mt-20">
            <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
              <img
                className="w-full"
                src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
                alt=""
              />
            </div>

            <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    1{" "}
                  </span>
                </div>
                <img
                  className="w-full rounded-lg "
                  src={require("../img/ussd/dial.png")}
                  alt=""
                />
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Dial *617*31#
                </h3>
              </div>

              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    2{" "}
                  </span>
                </div>
                <img
                  className="w-full rounded-lg "
                  src={require("../img/ussd/dashboard.png")}
                  alt=""
                />
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Access Dashboard
                </h3>
              </div>

              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    3{" "}
                  </span>
                </div>
                <img
                  className="w-full rounded-lg "
                  src={require("../img/ussd/statement.png")}
                  alt=""
                />
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Download Statements
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-10 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900">
        <div className="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-2">
          <div className="relative  container mx-auto px-4">
            <img
              className="hidden md:block  absolute top-20 right-0 -mr-30 lg:-mr-40 xl:-mr-12 w-10/12 lg:w-auto"
              src={require("../img/phone-right-xl1.png")}
              alt=""
            />
          </div>
          <div className="relative ">
            <div className="max-w-sm md:max-w-md lg:max-w-lg mx-auto md:mx-0 md:py-12  md:pt-40">
              <h2 className="font-heading text-2xl sm:text-6xl lg:text-7xl tracking-tight text-gray-300 mb-8">
                Get our mobile app to make the process faster
              </h2>
              <p className="max-w-sm lg:max-w-md md:text-xl mb-12 text-white">
                Digitizing the ‘underbanked’ Chamas through USSD *617*31#
              </p>
              <p className="max-w-sm lg:max-w-md md:text-xl mb-12 text-white">
                Mobile App Coming soon !
              </p>
              <div className="flex max-w-sm">
                <span className="inline-block mr-6 cursor-pointer">
                  <img
                    src={require("../img/appstore.png")}
                    alt=""
                    className="hover:scale-105"
                  />
                </span>
                <span className="inline-block cursor-pointer">
                  <img
                    src={require("../img/playstore.png")}
                    alt=""
                    className="hover:scale-105"
                  />
                </span>
              </div>
            </div>

            <img
              className="block md:hidden w-full"
              src={require("../img/phone-right-xl1.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="py-10 bg-gray-100 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="md:text-3xl text-2xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">
              Trusted by <span className="text-blue-600">20k+</span> Chama
              members & users{" "}
            </h2>
          </div>

          <div className="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-20 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
            <div className="overflow-hidden bg-white rounded-md shadow">
              <div className="px-8 py-12">
                <div className="relative w-24 h-24 mx-auto">
                  <img
                    className="relative object-cover w-24 h-24 mx-auto rounded-full"
                    src={require("../img/female1.png")}
                    alt=""
                  />
                  <div className="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-7 h-7">
                    <svg
                      className="w-4 h-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote className="mt-7">
                  <p className="text-lg text-black">
                    ~ “ I never thought managing our chama's finances could be
                    so easy until we found SaffeChama. With just a few clicks,
                    we can now save, borrow, and invest seamlessly. It's like
                    having our own personal banking system tailored to our
                    needs. Thank you, SaffeChama, for making our chama stronger
                    and more financially empowered!. “
                  </p>
                </blockquote>
                <p className="text-base font-semibold tex-tblack mt-9">
                  Grace Wambui
                </p>
                <p className="mt-1 text-base text-gray-600">
                  Nairobi Shg Chairperson
                </p>
              </div>
            </div>

            <div className="overflow-hidden bg-white rounded-md shadow">
              <div className="px-8 py-12">
                <div className="relative w-24 h-24 mx-auto">
                  <img
                    className="relative object-cover w-24 h-24 mx-auto rounded-full"
                    src={require("../img/male.png")}
                    alt=""
                  />
                  <div className="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-7 h-7">
                    <svg
                      className="w-4 h-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote className="mt-7">
                  <p className="text-lg text-black">
                    ~ “ SaffeChama has been a game-changer for our chama group.
                    No more paperwork or manual calculations; everything is
                    automated, secure, and efficient. We can focus on achieving
                    our financial goals and building a brighter future together.
                    As a member, I'm grateful for the convenience and financial
                    inclusion SaffeChama has brought into our lives. “
                  </p>
                </blockquote>
                <p className="text-base font-semibold tex-tblack mt-9">
                  Ali Muuhamed
                </p>
                <p className="mt-1 text-base text-gray-600">Nyali Shg Member</p>
              </div>
            </div>

            <div className="overflow-hidden bg-white rounded-md shadow">
              <div className="px-8 py-12">
                <div className="relative w-24 h-24 mx-auto">
                  <img
                    className="relative object-cover w-24 h-24 mx-auto rounded-full"
                    src={require("../img/female.png")}
                    alt=""
                  />
                  <div className="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-7 h-7">
                    <svg
                      className="w-4 h-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote className="mt-7">
                  <p className="text-lg text-black">
                    ~ “ As a small business owner, accessing credit from
                    traditional banks was always a challenge. SaffeChama came to
                    our rescue by providing us with fair and accessible credit
                    options. Now, we can grow our business and take it to new
                    heights. It's incredible how technology can bridge the gap
                    between the underbanked and financial services. We owe our
                    success to SaffeChama! “
                  </p>
                </blockquote>
                <p className="text-base font-semibold tex-tblack mt-9">
                  Sarah Kwamboka
                </p>
                <p className="mt-1 text-base text-gray-600">
                  Entrepreneur Shg Member
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24" id="faq">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="md:text-3xl text-2xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Why choose SaffeChama
            </h2>
          </div>

          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16 ">
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      Convenience and Efficiency
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" rounded-lg bg-white px-6 py-6">
                    The platform simplifies chama management by automating tasks
                    such as record-keeping, contribution tracking, loan
                    management, and communication. Members can access their
                    chama accounts, contribute savings, apply for loans, and
                    receive notifications using their smartphones or computers.
                    This convenience saves time and effort for both chama
                    members and administrators.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      Transparency and Accountability
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    SaffeChama enhances transparency and accountability within
                    chamas. It provides a clear record of contributions, loans,
                    repayments, and other financial transactions. This
                    transparency fosters trust among chama members and ensures
                    that the financial operations are conducted in a fair and
                    accountable manner.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure className="mt-2 ">
              {({ open }) => (
                <>
                  <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                    <span className="flex text-lg font-semibold text-black">
                      Security and Data Privacy
                    </span>
                    <span
                      className={`${
                        !open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    >
                      <i className="fas fa-chevron-up"></i>{" "}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                    SaffeChama prioritizes the security and privacy of chama
                    data. The platform implements robust security measures to
                    protect sensitive information, such as member details and
                    financial transactions, from unauthorized access. By
                    leveraging secure technology, SaffeChama provides a safe and
                    reliable platform for managing chama finances.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </section>

      <section className="py-10 bg-gray-100 sm:py-16 lg:py-24" id="contact">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Contact us
            </h2>
          </div>

          <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
            <div className="grid grid-cols-1 gap-6  text-center  md:grid-cols-3">
              <div className="overflow-hidden bg-white rounded-xl">
                <div className="p-6">
                  <svg
                    className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <p className="mt-6 text-lg font-medium text-gray-900">
                    +254-716-003-792{" "}
                  </p>
                  <p className="mt-1 text-lg font-medium text-gray-900">
                    +254-704-618-977
                  </p>
                </div>
              </div>

              <div className="overflow-hidden bg-white rounded-xl">
                <div className="p-6">
                  <svg
                    className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <p className="mt-6 text-lg font-medium text-gray-900">
                    info@saffechama.com
                  </p>
                  <p className="mt-1 text-lg font-medium text-gray-900">
                    help@saffechama.com
                  </p>
                </div>
              </div>

              <div className="overflow-hidden bg-white rounded-xl">
                <div className="p-6">
                  <svg
                    className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <p className="mt-6 text-lg font-medium leading-relaxed text-gray-900">
                    Location: 8th Floor, Amways Office, Westpark Towers, Off
                    Muthithi Road, Westlands
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 overflow-hidden bg-white rounded-xl">
              <div className="px-6 py-12 sm:p-12">
                <h3 className="md:text-3xl text-2xl font-semibold text-center text-gray-900">
                  Send us a message
                </h3>

                <form action="#" method="POST" className="mt-14">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                    <div>
                      <label className="text-base font-medium text-gray-900">
                        {" "}
                        Your name{" "}
                      </label>
                      <div className="mt-2.5 relative">
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter your full name"
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="text-base font-medium text-gray-900">
                        {" "}
                        Email address{" "}
                      </label>
                      <div className="mt-2.5 relative">
                        <input
                          type="email"
                          name=""
                          id=""
                          placeholder="Enter your full name"
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label className="text-base font-medium text-gray-900">
                        {" "}
                        Message{" "}
                      </label>
                      <div className="mt-2.5 relative">
                        <textarea
                          name=""
                          id=""
                          placeholder=""
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200  border border-transparent rounded-md bg-gradient-to-r from-fuchsia-600 to-blue-600 focus:outline-none hover:opacity-80 focus:opacity-80"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 sm:pt-6 lg:pt-6">
        <footer className="bg-white/10 rounded-lg shadow m-4 ">
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-100 sm:text-center ">
              © {year} All Rights Reserved, Msimbo DevOps.
            </span>
            <span className="text-sm flex items-center text-gray-100 sm:text-center ">
              <span className="mr-2">Powered by </span>
              <a href="https://sasapay.co.ke/" target="_blank" rel="noreferrer">
                <img
                  src={require("../img/sasapay.png")}
                  alt="lg"
                  className="w-24"
                />
              </a>
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-100  sm:mt-0">
              <li>
                <Link to="/" className="mr-4 hover:underline  ">
                  <span className="mr-1">Home</span> |
                </Link>
              </li>

              <li>
                <Link to="/faq" className="mr-4 hover:underline  ">
                  <span className="mr-1">FAQs</span> |
                </Link>
              </li>

              <li>
                <Link className="mr-4 hover:underline" to="/terms">
                  <span className="mr-1">Terms and Conditions</span>|
                </Link>
              </li>
              <li>
                <Link className=" mr-4 hover:underline" to="/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </section>
    </>
  );
}

export default Home;
