import React, { useState } from "react";
import { Link } from "react-router-dom";

const Submenu = ({ item, paddingLeft = 10 }) => {
  const [isSubnavOpen, setIsSubnavOpen] = useState(false);
  const toggleSubnav = () => setIsSubnavOpen((state) => !state);

  return (
    <>
      <li className="mt-0.5 w-full ">
        <Link
          to={item.path}
          onClick={item.subNav && toggleSubnav}
          style={{ paddingLeft: paddingLeft }}
          className="dark:text-white dark:opacity-80 py-2.7  ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4  hover:bg-gray-200 hover:rounded-lg hover:font-semibold hover:text-slate-700"
        >
          <span className="mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
            {item.icon}
          </span>
          <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
            {item.title}
          </span>

          <span className={`transition ${isSubnavOpen ? "rotate-180" : ""}`}>
            {item.subNav ? <i className="fa-solid fa-angle-down"></i> : null}
          </span>
        </Link>

        <ul
          className={`${
            isSubnavOpen ? "h-auto max-h-[1000px]" : "max-h-0"
          } transition-all duration-300 overflow-y-hidden`}
        >
          {item.subNav &&
            item.subNav.map((item, i) => (
              <Submenu item={item} paddingLeft={paddingLeft + 20} key={i} />
            ))}
        </ul>
      </li>
    </>
  );
};

export default Submenu;
