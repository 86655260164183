import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function AddShg() {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);

  const [accountname, setAccountName] = useState("");
  const [userName, setUserName] = useState("");
  const [mobile_no, setMobile] = useState("");
  const [mpesaexpress_shortcode, setMpesaexpress_shortcode] = useState("");
  const [b2c_shortcode, setB2c_shortcode] = useState("");
  const [initiator_password, setInitiator_password] = useState("");
  const [consumer_key_mpesa_express, setConsumer_key_mpesa_express] =
    useState("");
  const [consumer_secret_mpesa_express, setConsumer_secret_mpesa_express] =
    useState("");
  const [passkey_mpesa_express, setPasskey_mpesa_express] = useState("");
  const [county, setCounty] = useState("");
  const [sub_county, setSub_county] = useState("");
  const [ward, setWard] = useState("");
  const [registration_status, setRegistration_status] = useState("registered");
  const [registration_number, setRegistration_number] = useState("");
  const [date_of_registration, setDate_of_registration] = useState("");
  const [postal_address, setPostal_address] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [bankDetails, setBankDetails] = useState([
    { bankAndBranch: "", accountNumber: "", accountName: "" },
  ]);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [paybillDetails, setPaybillDetails] = useState({
    paybillTillNumber: "",
    paybillTillName: "",
  });
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);

  const [displayAffiliate, setDisplayAffiliate] = useState("");

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getalluserinfo",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        const affiliatess = res.data.data;
        // console.log(res.data.data);
        const data = Object.values(affiliatess);

        const affiliates = data.filter((person) => person.role === "affiliate");
        // console.log(affiliates);
        setMobile(affiliates[0].mobile_no);
        setDisplayAffiliate(affiliates);
      })
      .catch((err) => console.log(err));
  }, []);

  const myAff = Object.values(displayAffiliate);
  const dataAff = myAff.map((data, index) => {
    return (
      <option key={index} value={data.mobile_no}>
        {data.name}
      </option>
    );
  });

  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.value);
  };

  const handleAddRow = () => {
    setBankDetails([
      ...bankDetails,
      { bankAndBranch: "", accountNumber: "", accountName: "" },
    ]);
  };

  const handleDeleteRow = (index) => {
    if (bankDetails.length > 1) {
      const updatedBankDetails = bankDetails.filter((_, i) => i !== index);
      setBankDetails(updatedBankDetails);
    }
  };

  const handleChange = (index, field, value) => {
    const updatedBankDetails = [...bankDetails];
    updatedBankDetails[index][field] = value;
    setBankDetails(updatedBankDetails);
  };

  const handlePaybillChange = (field, value) => {
    setPaybillDetails({ ...paybillDetails, [field]: value });
  };

  const createSHG = (e) => {
    e.preventDefault();

    if (
      accountname &&
      mobile_no &&
      mpesaexpress_shortcode &&
      b2c_shortcode &&
      initiator_password &&
      consumer_key_mpesa_express &&
      consumer_secret_mpesa_express &&
      passkey_mpesa_express &&
      county &&
      sub_county &&
      ward &&
      registration_status &&
      registration_number &&
      date_of_registration &&
      postal_address &&
      email &&
      telephone &&
      selectedCheckbox &&
      bankDetails
    ) {
      const completeFormValue = {
        bankDetails,
        paybillDetails,
      };
      setLoading(true);
      Axios.post(
        "https://us-central1-safechama.cloudfunctions.net/getsingleuser",
        { message: { access_token: token, mobile_no: mobile_no } },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          // console.log("GEtting Users Data ::", res.data);
          setUserName(res.data.data.name);
        })
        .catch((err) => console.log(err));

      Axios.post(
        "https://us-central1-safechama.cloudfunctions.net/createSelfHelpGroupAccount",
        {
          message: {
            access_token: token,
            accountname: accountname,
            mobile_no: mobile_no,
            created_by: userName,
            date_created: date,
            mpesaexpress_shortcode: mpesaexpress_shortcode,
            b2c_shortcode: b2c_shortcode,
            initiator_password: initiator_password,
            consumer_key_mpesa_express: consumer_key_mpesa_express,
            consumer_secret_mpesa_express: consumer_secret_mpesa_express,
            passkey_mpesa_express: passkey_mpesa_express,
            group_name: accountname,
            county: county,
            sub_county: sub_county,
            ward: ward,
            registration_status: registration_status,
            registration_number: registration_number,
            date_of_registration: date_of_registration,
            postal_address: postal_address,
            email: email,
            telephone: telephone,
            type_of_group: selectedCheckbox,
            bank_details: completeFormValue,
          },
        }
      )
        .then((res) => {
          if (res.data.code === 500) {
            setLoading(false);
            MySwal.fire({
              position: "center",
              icon: "success",
              title: "Shg Created Successfully",
            });
            navigate("/sgh");
          } else if (res.data.code === 606) {
            setLoading(false);
            setuserErr(true);
          }
          // console.log("Posting Data From::", res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setallErr(true);
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && "hidden"
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main className="pt-2 md:pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink mx-auto max-w-full  w-full lg:w-3/4 mb-6">
              <div
                className={`${
                  !allErr && "hidden"
                } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-orange-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Creating SGH
                    </p>
                    <p className="text-sm">* All Fields are Required</p>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  !userErr && "hidden"
                } bg-yellow-100 my-5    border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-yellow-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Creating SGH
                    </p>
                    <p className="text-sm">SGH Exists</p>
                  </div>
                </div>
              </div>
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
                <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                  Add New SHG
                </p>
                <div className="flex-1 p-6 bg-gray-100 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <form className="flex flex-wrap flex-row -mx-4">
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        SHG Name
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={accountname}
                        onChange={(e) => {
                          setAccountName(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div>
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Affiliate Name
                      </label>

                      <select
                        name="affiliate"
                        id="affiliate"
                        className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                        value={mobile_no}
                        aria-label="affiliate"
                        onChange={(e) => {
                          setMobile(e.target.value);
                          setallErr(false);
                        }}
                      >
                        {dataAff}
                      </select>
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Mpesa Express Shortcode
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={mpesaexpress_shortcode}
                        onChange={(e) => {
                          setMpesaexpress_shortcode(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        B2C Shortcode
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={b2c_shortcode}
                        onChange={(e) => {
                          setB2c_shortcode(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Initiator Password
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={initiator_password}
                        onChange={(e) => {
                          setInitiator_password(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Consumer Key
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={consumer_key_mpesa_express}
                        onChange={(e) => {
                          setConsumer_key_mpesa_express(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Consumer Secret
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={consumer_secret_mpesa_express}
                        onChange={(e) => {
                          setConsumer_secret_mpesa_express(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Passkey
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={passkey_mpesa_express}
                        onChange={(e) => {
                          setPasskey_mpesa_express(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        County
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={county}
                        onChange={(e) => {
                          setCounty(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Sub County
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={sub_county}
                        onChange={(e) => {
                          setSub_county(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Ward
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={ward}
                        onChange={(e) => {
                          setWard(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div>
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Registration_status
                      </label>

                      <select
                        name="status"
                        id="status"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        value={registration_status}
                        aria-label="status"
                        onChange={(e) => {
                          setRegistration_status(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      >
                        <option value="Registered">Registered</option>
                        <option value="Unregistered">Unregistered</option>
                      </select>
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Registration Number
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={registration_number}
                        onChange={(e) => {
                          setRegistration_number(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Date of Registration
                      </label>

                      <input
                        type="date"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none`}
                        id="inputname"
                        value={date_of_registration}
                        onChange={(e) => {
                          setDate_of_registration(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Postal Address
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={postal_address}
                        onChange={(e) => {
                          setPostal_address(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Email
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        Telephone
                      </label>
                      <input
                        type="text"
                        className={`${
                          userErr && " border-yellow-500 border text-yellow-900"
                        } w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                        id="inputname"
                        value={telephone}
                        onChange={(e) => {
                          setTelephone(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      />
                    </div>

                    <div className=" px-4 w-full  mb-6">
                      <label className="block text-gray-700 font-bold mb-2">
                        Type of Group:
                      </label>
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="youth"
                        checked={selectedCheckbox === "youth"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Youth</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="women"
                        checked={selectedCheckbox === "women"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Women</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="men"
                        checked={selectedCheckbox === "men"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Men</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="mixed"
                        checked={selectedCheckbox === "mixed"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Mixed</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="pwd"
                        checked={selectedCheckbox === "pwd"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2"> PWD</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="older persons"
                        checked={selectedCheckbox === "older persons"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Older Persons</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="church"
                        checked={selectedCheckbox === "church"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Church</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="investment"
                        checked={selectedCheckbox === "investment"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Investment</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="endowment"
                        checked={selectedCheckbox === "endowment"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Endowment</span>
                    </div>
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/3 mb-6">
                      <input
                        type="radio"
                        value="others"
                        checked={selectedCheckbox === "others"}
                        onChange={handleCheckboxChange}
                        className="form-radio w-4.8 h-4.8 ease  rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                      />
                      <span className="ml-2">Others</span>
                    </div>

                    <div>
                      <label className="block text-gray-700 font-bold mb-2">
                        Bank Details
                      </label>
                      {bankDetails.map((detail, index) => (
                        <div key={index} className="flex flex-wrap -mx-3">
                          <div class="w-full max-w-full px-3 shrink-0 md:w-1/3 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="first name"
                                class="inline-block mb-2 ml-1   "
                              >
                                Bank and Branch
                              </label>
                              <input
                                type="text"
                                placeholder="Bank and Branch"
                                value={detail.bankAndBranch}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "bankAndBranch",
                                    e.target.value
                                  )
                                }
                                className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-1/3 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="first name"
                                class="inline-block mb-2 ml-1  "
                              >
                                Account Number
                              </label>

                              <input
                                type="text"
                                placeholder="Account Number"
                                value={detail.accountNumber}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "accountNumber",
                                    e.target.value
                                  )
                                }
                                className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-1/3 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="first name"
                                class="inline-block mb-2 ml-1  "
                              >
                                Account Name
                              </label>

                              <input
                                type="text"
                                placeholder="Account Name"
                                value={detail.accountName}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "accountName",
                                    e.target.value
                                  )
                                }
                                className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          {index !== 0 && (
                            <div class="w-full max-w-full px-3 shrink-0 md:w-1/4 md:flex-0">
                              <button
                                type="button"
                                onClick={() => handleDeleteRow(index)}
                                className="inline-block px-8 py-2 mb-6  ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-red-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                              >
                                <i className="fas fa-trash-alt"></i> Del
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={handleAddRow}
                        className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                      >
                        Add Row +
                      </button>
                    </div>

                    <div className="mt-5">
                      <label className="block text-gray-700 font-bold mb-2">
                        Paybill/Till Details if applicable
                      </label>

                      <div className="flex mb-4">
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-200">
                            Paybill/Till Number
                          </label>
                          <input
                            type="text"
                            value={paybillDetails.paybillTillNumber}
                            onChange={(e) =>
                              handlePaybillChange(
                                "paybillTillNumber",
                                e.target.value
                              )
                            }
                            placeholder="Paybill/Till Number"
                            className="w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700"
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-200">
                            Paybill/Till Name
                          </label>
                          <input
                            type="text"
                            placeholder="Paybill/Till Name"
                            value={paybillDetails.paybillTillName}
                            onChange={(e) =>
                              handlePaybillChange(
                                "paybillTillName",
                                e.target.value
                              )
                            }
                            className="w-full leading-5   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-shrink max-w-full mt-4 xl:mt-0 px-4 w-full">
                      <button
                        type="submit"
                        onClick={createSHG}
                        className=" text-center mb-3 rounded py-2 px-5 inline-block   leading-5 text-gray-100 bg-indigo-500 bg-transparent border border-indigo-500 hover:text-gray-100 hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"
                      >
                        Add SGH +
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddShg;
