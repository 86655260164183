import Axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../Context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
function Try() {
  const [mobile_no, setMobile] = useState("");
  const [lpassword, setLPassword] = useState("");
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const { setLogged } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserID } = useContext(authContext);
  const { setUserRole } = useContext(authContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);
  const [isTermsAccepted, setTermsAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setTermsAccepted(!isTermsAccepted);
  };

  const [formUrl] = useState(
    "https://forms.zohopublic.com/abnyabera/form/ClientDetails/formperma/FDtx4MK6fAgsSrVRg1te20Xnuv8X-_3qr4IK5aqVnlg"
  );
  const [formWidth] = useState(700);
  const [formHeight] = useState(648);

  const openFormWindow = () => {
    const leftPos = (window.screen.width - formWidth) / 2;
    const topPos = (window.screen.height - formHeight) / 2;
    const windowFeatures = `width=${formWidth},height=${formHeight},left=${leftPos},top=${topPos},toolbar=0,location=0,status=1,scrollbars=1,resizable=1`;

    window.open(formUrl, null, windowFeatures);
  };

  const newDate = new Date();
  const year = newDate.getFullYear();

  const userLogin = (e) => {
    e.preventDefault();
    if (mobile_no && lpassword) {
      if (isTermsAccepted) {
        setLoading(true);
        Axios.post(
          "https://us-central1-safechama.cloudfunctions.net/basicauth",
          { message: { mobile_no: mobile_no, password: lpassword } },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            // console.log(res.data);
            localStorage.setItem("userToken", res.data.access_token);
            // const token = localStorage.getItem("userToken");

            Axios.post(
              "https://us-central1-safechama.cloudfunctions.net/Login",
              {
                message: {
                  access_token: res.data.access_token,
                  mobile_no: mobile_no,
                  lpassword: lpassword,
                },
              },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
              .then((res) => {
                // console.log("Posting Data From::", res.data);

                if (res.data.response === "Successfully") {
                  setLoading(false);
                  MySwal.fire({
                    position: "center",
                    icon: "success",
                    title: "Login Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  let userMobile = {
                    message: {
                      access_token: localStorage.getItem("userToken"),
                      mobile_no: mobile_no,
                    },
                  };

                  Axios.post(
                    "https://us-central1-safechama.cloudfunctions.net/getsingleuser",
                    userMobile,
                    {
                      headers: { "Content-Type": "application/json" },
                    }
                  )
                    .then((res) => {
                      localStorage.setItem("userMobile", mobile_no);

                      if (res.data.data.role === "admin") {
                        localStorage.setItem("userRole", res.data.data.role);
                        setLogged(true);
                        setUserName(res.data.data.name);
                        setUserMobile(res.data.data.mobile_no);
                        setUserID(res.data.data.id_no);
                        setUserRole(res.data.data.role);
                        navigate("/dashboard");
                      } else if (res.data.data.role === "affiliate") {
                        localStorage.setItem("userRole", res.data.data.role);
                        setLogged(true);
                        setUserName(res.data.data.name);
                        setUserMobile(res.data.data.mobile_no);
                        setUserID(res.data.data.id_no);
                        setUserRole(res.data.data.role);
                        navigate("/affDashboard");
                      } else {
                        navigate("/userSelectSHG");
                      }
                    })
                    .catch((err) => console.log(err));
                } else if (res.data.code === 505) {
                  setpasswordErr(true);
                } else if (res.data.code === 404) {
                  setuserErr(true);
                }
              })
              .catch((err) => {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Login Error",
                  text: "Check Your Credentials!",
                });
                setuserErr(true);
                setpasswordErr(true);
                navigate("/signIn");
              });
          })
          .catch((err) => {
            setLoading(false);
            MySwal.fire({
              position: "center",
              icon: "error",
              title: "Login Error",
              text: "Check Your Credentials!",
            });
            setuserErr(true);
            setpasswordErr(true);
          });
      } else {
        MySwal.fire({
          position: "center",
          icon: "error",
          title: "Agree To Terms And Conditions",
        });
      }
    } else {
      setallErr(true);
    }
  };

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      checkLogin();

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem("userMobile")) {
      setLogged(true);
      const useRole = localStorage.getItem("userRole");

      if (useRole === "admin") {
        navigate("/dashboard");
      } else if (useRole === "chairman") {
        navigate("/chairDashboard");
      } else if (useRole === "secretary") {
        navigate("/secDashboard");
      } else if (useRole === "treasurer") {
        navigate("/tresDashboard");
      } else if (useRole === "affiliate") {
        navigate("/affDashboard");
      } else if (useRole === "user") {
        navigate("/userDashboard");
      }
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && "hidden"
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>

      <div className="bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 max-w-full  ">
        <div className="md:flex md:flex-wrap md:flex-row">
          <div className="md:flex-shrink md:max-w-full w-full md:min-h-screen  lg:w-1/3  z-30">
            <div className="max-w-full w-full h-full md:px-6 sm:px-12 bg-white  shadow-lg z-40">
              <div className="relative">
                <div className="p-6 sm:p-8">
                  <div
                    className={`${
                      !allErr && "hidden"
                    } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                    role="alert"
                  >
                    <div className="flex">
                      <div className="py-1">
                        <svg
                          className="fill-current h-6 w-6 text-orange-500 mr-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                        </svg>
                      </div>
                      <div>
                        <p className="font-bold">Error Occured While Login</p>
                        <p className="text-sm">* All Fields are Required</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      !userErr && "hidden"
                    } bg-red-100 my-5    border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md`}
                    role="alert"
                  >
                    <div className="flex">
                      <div className="py-1">
                        <svg
                          className="fill-current h-6 w-6 text-red-500 mr-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                        </svg>
                      </div>
                      <div>
                        <p className="font-bold">Error Occured While Login</p>
                        <p className="text-sm">Insert Correct Credentials</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center px-4  bg-white sm:px-6 lg:px-8 ">
                    <div className="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto">
                      <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl">
                        Login To Saffechama
                      </h2>
                      <img
                        src={require("../img/icond.png")}
                        className="w-40 mx-auto my-4"
                        alt="log"
                      />

                      <form className="mt-8">
                        <div className="space-y-5">
                          <div>
                            <label
                              for=""
                              className="text-base font-medium text-gray-900"
                            >
                              {" "}
                              PhoneNo.{" "}
                            </label>
                            <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  className="bi bi-phone w-8 h-8"
                                  viewBox="0 0 24 24"
                                >
                                  {" "}
                                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />{" "}
                                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />{" "}
                                </svg>
                              </div>

                              <input
                                id="inputmobile"
                                name="mobile_no"
                                type="tel"
                                value={mobile_no}
                                aria-label="mobile_no"
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                  setuserErr(false);
                                  setallErr(false);
                                }}
                                placeholder="254*********"
                                className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              for=""
                              className="text-base font-medium text-gray-900"
                            >
                              {" "}
                              PIN{" "}
                            </label>
                            <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                  className="w-5 h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                                  />
                                </svg>
                              </div>

                              <input
                                aria-label="lpassword"
                                type="password"
                                value={lpassword}
                                onChange={(e) => {
                                  setLPassword(e.target.value);
                                  setuserErr(false);
                                  setpasswordErr(false);
                                  setallErr(false);
                                }}
                                placeholder="****"
                                className={`${
                                  passwordErr &&
                                  " border-red-500 border text-red-900"
                                }"block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600`}
                              />
                            </div>
                          </div>
                          <div className="min-h-6 pl-7  block my-6">
                            <input
                              className="w-4.8 h-4.8 ease -ml-7 rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                              type="checkbox"
                              checked={isTermsAccepted}
                              onChange={handleCheckboxChange}
                            />
                            <label className="mb-2 ml-1 font-normal  text-sm text-slate-700 ">
                              {" "}
                              I agree to the{" "}
                              <Link to="/terms">
                                <span className="font-bold text-slate-700  hover:text-blue-800">
                                  Terms and Conditions
                                </span>{" "}
                              </Link>
                            </label>
                          </div>

                          <div>
                            <button
                              onClick={userLogin}
                              type="button"
                              className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-gradient-to-r from-fuchsia-600 to-blue-600 focus:outline-none hover:opacity-80 focus:opacity-80"
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="inline-block w-4 h-4 mr-2 bi bi-box-arrow-in-right"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                                <path d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                              </svg>
                              Login
                            </button>
                          </div>
                          <p>- Or -</p>
                          <div>
                            {/* Your other login form elements can go here */}

                            <a
                              href={formUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-gradient-to-r from-violet-600 to-green-600 focus:outline-none hover:opacity-80 focus:opacity-80"
                              title="SaffeChama Affiliate/Partner Registration Form"
                              onClick={(e) => {
                                e.preventDefault();
                                openFormWindow();
                              }}
                            >
                              <i className="fas fa-users mr-2"></i> Register
                              your Chama
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex-shrink md:max-w-full w-full md:min-h-screen  lg:w-2/3   z-30">
            <section className="relative   overflow-hidden">
              <div className="relative  container mx-auto px-4">
                <img
                  className="hidden md:block absolute top-20 right-0 -mr-30 lg:-mr-40 xl:-mr-40 w-10/12 lg:w-auto"
                  src={require("../img/phone-right-xl1.png")}
                  alt=""
                />
                <img
                  src={require("../img/logow.png")}
                  alt="log"
                  className="w-full lg:w-2/3"
                />
                <div className="relative ">
                  <div className="max-w-sm md:max-w-md lg:max-w-lg mx-auto md:mx-0 py-12  md:pt-40">
                    <h2 className="font-heading text-4xl sm:text-6xl lg:text-7xl  tracking-tight text-gray-300 mb-8">
                      Get our mobile app to make the process faster
                    </h2>
                    <p className="max-w-sm lg:max-w-md text-xl mb-12 text-white">
                      Digitizing the ‘underbanked’ Chamas through USSD *617*31#
                    </p>
                    <p className="max-w-sm lg:max-w-md text-xl mb-12 text-white">
                      Mobile App Coming soon !
                    </p>
                    <div className="flex max-w-sm">
                      <span className="inline-block mr-6 cursor-pointer">
                        <img
                          src={require("../img/appstore.png")}
                          alt=""
                          className="hover:scale-105"
                        />
                      </span>
                      <span className="inline-block cursor-pointer">
                        <img
                          src={require("../img/playstore.png")}
                          alt=""
                          className="hover:scale-105"
                        />
                      </span>
                    </div>
                  </div>

                  <img
                    className="block md:hidden w-full"
                    src={require("../img/phone-right-xl1.png")}
                    alt=""
                  />
                  <footer className="bg-white/10 rounded-lg shadow m-4 ">
                    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                      <span className="text-sm text-gray-100 sm:text-center ">
                        © {year} All Rights Reserved, Msimbo DevOps.
                      </span>
                      <span className="text-sm flex items-center text-gray-100 sm:text-center ">
                        <span className="mr-2">Powered by </span>
                        <a
                          href="https://sasapay.co.ke/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={require("../img/sasapay.png")}
                            alt="lg"
                            className="w-24"
                          />
                        </a>
                      </span>
                      <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-100  sm:mt-0">
                        <li>
                          <Link to="/" className="mr-4 hover:underline  ">
                            <span className="mr-1">Home</span> |
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq" className="mr-4 hover:underline  ">
                            <span className="mr-1">FAQs</span> |
                          </Link>
                        </li>

                        <li>
                          <Link className="mr-4 hover:underline" to="/terms">
                            <span className="mr-1">Terms and Conditions</span>|
                          </Link>
                        </li>
                        <li>
                          <Link className=" mr-4 hover:underline" to="/privacy">
                            Privacy Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </footer>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Try;
