import React, { useContext, useEffect, useRef, useState } from "react";
import Axios from "axios";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";

function SecMemberView() {
  const [userName, setUserName] = useState();
  const [userID, setUserID] = useState();
  const [userEmail, setUserEmail] = useState();

  const { mobile_no } = useParams();
  const userSHG = localStorage.getItem("userSHG");
  const token = localStorage.getItem("userToken");
  const pageSize = 5;
  const [shg, setSHG] = useState([]);
  const [savings, setSavings] = useState([]);
  const [userSavings, setUserSavings] = useState();
  const [paginatedSavings, setPaginatedSavings] = useState([]);
  const [currentSavingsPage, setCurrentSavingsPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const tableRef = useRef(null);
  const shgRef = useRef(null);

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getsingleuser",
      { message: { access_token: token, mobile_no: mobile_no } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting Users Data ::", res.data);
        setUserName(res.data.data.name);
        setUserID(res.data.data.id_no);
        setUserEmail(res.data.data.email);
      })
      .catch((err) => console.log(err));
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getmemberaccounts",
      { message: { access_token: token, mobile_no: mobile_no } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setSHG(res.data.data);
      })
      .catch((err) => console.log(err));
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getmembersavings",
      { message: { access_token: token, mobile_no: mobile_no } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        const myResults = Object.values(res.data.data);
        if (myResults.length > 0) {
          setSavings(res.data.data);
          setPaginatedSavings(
            _(Object.values(res.data.data)).slice(0).take(pageSize).value()
          );
        }

        setLoading(true);
      })
      .catch((err) => console.log(err));
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/statistics",
      {
        message: {
          access_token: token,
          option: "totalsavingsofusershg",
          account_name: userSHG,
          mobile_no: mobile_no,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.total_amount);
        setUserSavings(res.data.total_amount);
      })
      .catch((err) => console.log(err));
  }, []);

  const myshg = Object.values(shg);

  const dataSHG = myshg.map((data, index) => {
    return (
      <tr key={index}>
        <td className="hidden lg:table-cell">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
          />
        </td>
        <td>{data.account_name} </td>
        <td className="hidden lg:table-cell">
          <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
            Active
          </span>
        </td>
      </tr>
    );
  });

  // console.log(paginatedSavings);

  const mySavings = Object.values(savings);
  const dataSavings = paginatedSavings.map((data, index) => {
    if (data.account_name == userSHG) {
      return (
        <tr
          key={index}
          class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
        >
          <td class="border-grey-light border hover:bg-gray-100 p-3">
            <input
              type="checkbox"
              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
            />
          </td>

          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {data.MpesaReceiptNumber}
          </td>

          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {data.amount}{" "}
          </td>
          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {data.date_deposited}
          </td>

          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
              Active
            </span>
          </td>
        </tr>
      );
    }
  });

  const pageSavingsCount = savings ? Math.ceil(mySavings.length / pageSize) : 0;

  // if (pageSavingsCount === 1) return null;
  const pageSavings = _.range(1, pageSavingsCount + 1);
  // console.log(pageSavingsCount);
  const pagination = (pageNo) => {
    setCurrentSavingsPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const fetchResults = _(Object.values(savings))
      .slice(startIndex)
      .take(pageSize)
      .value();

    setPaginatedSavings(fetchResults);
  };

  return (
    <>
      <main className="pt-6 md:pt-20 -mt-2 md:m-5 m-2">
        <div className="mx-auto md:p-2">
          <div className="flex-shrink max-w-full md:px-4 w-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden pb-8">
              <div className="h-40 overflow-hidden relative">
                <img
                  src={require("../../img/bg-profile.jpg")}
                  className="w-full"
                  alt="background"
                />
              </div>
              <div className="text-center ">
                <div className="relative flex justify-center -mt-10">
                  <img
                    src={require("../../img/avatar8.png")}
                    className="rounded-full w-24  bg-gray-200 border-solid border-white border-2 -mt-3"
                    alt="profile"
                  />
                </div>

                <div className="md:px-6 py-3 text-xl border-b dark:border-gray-800 font-bold dark:text-gray-300 font-andika">
                  Member Profile
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-4 flex-wrap flex-row">
            <div className="flex-shrink mx-auto max-w-full md:px-4 w-full lg:w-2/3 mb-6">
              <div className="md:px-6 py-3 text-xl border-b dark:border-gray-800 font-bold dark:text-gray-300 font-andika"></div>
              <div className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                <div className="flex-1 p-2 md:p-6  dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                    <div class="flex items-center justify-between">
                      <h4 class="dark:text-white font-semibold my-3">
                        User's SHGs
                      </h4>
                      <DownloadTableExcel
                        filename={`${userName} Shgs`}
                        sheet="shgs"
                        currentTableRef={shgRef.current}
                      >
                        <button
                          type="button"
                          class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                        >
                          Export Excel <i className="fas fa-download"></i>
                        </button>
                      </DownloadTableExcel>
                    </div>
                  </div>

                  <table
                    className="table-sorter table-bordered w-full text-left text-gray-600 dark:text-gray-400"
                    ref={shgRef}
                  >
                    <thead>
                      <tr className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-40">
                        <th
                          data-sortable="false"
                          className="hidden lg:table-cell"
                        >
                          <input
                            id="check_all"
                            type="checkbox"
                            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                          />
                        </th>

                        <th>Name</th>
                        <th className="hidden lg:table-cell">Status</th>
                      </tr>
                    </thead>
                    <tbody>{dataSHG}</tbody>
                  </table>
                </div>
              </div>

              <div className="w-full max-w-full px-3 mb-6  sm:flex-none xl:mb-0  my-5">
                <div className="relative flex flex-col hover:scale-105 min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                  <div className="flex-auto p-4">
                    <div className="flex flex-row -mx-3">
                      <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                          <p className="mb-2 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                            Total Savings on {userSHG}
                          </p>
                          <h5 className="mb-2 font-bold text-4xl dark:text-white">
                            <span className="opacity-0">0</span>
                            {userSavings}
                          </h5>
                          <p className="mb-2 dark:text-white dark:opacity-60">
                            <span className="text-sm font-bold leading-normal text-emerald-500">
                              <i className="fas fa-arrow-right"></i> KES
                            </span>
                            <br />
                            Transactions
                          </p>
                        </div>
                      </div>
                      <div className="px-3 text-right basis-1/3">
                        <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                          <i className=" leading-none fas fa-piggy-bank text-lg relative top-3.5 text-white"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-shrink max-w-full md:px-4 w-full lg:w-1/3 mb-6">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                <img
                  class="w-full rounded-t-2xl"
                  src={require("../../img/bg-profile.jpg")}
                  alt="profile"
                />
                <div class="flex flex-wrap justify-center -mx-3">
                  <div class="w-4/12 max-w-full px-3 flex-0 ">
                    <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                      <img
                        class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                        src={require("../../img/avatar8.png")}
                        alt="profile"
                      />
                    </div>
                  </div>
                </div>
                <div class="border-black/12.5 rounded-t-2xl p-6 text-center pt-0 pb-6 lg:pt-2 lg:pb-4">
                  <div class="flex justify-between">
                    {/* <Link to={`/secUserUpdate/${mobile_no}`}>
                      <button
                        type="button"
                        class="hidden px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                      >
                        Edit
                      </button>
                    </Link> */}
                    {/* <Link to={`/secUserUpdate/${mobile_no}`}>
                      <button
                        type="button"
                        class="block px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:hidden tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                      >
                        <i class="fas fa-edit "></i>
                      </button>
                    </Link> */}
                  </div>
                </div>

                <h1 class="ml-2 text-gray-800 dark:text-gray-100 font-akaya my-3 text-xl">
                  Personal Details
                </h1>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-user"></i>
                    </span>{" "}
                    &nbsp;Name
                  </h1>
                  <span class="dark:text-gray-100">{userName}</span>
                </div>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-address-book"></i>
                    </span>{" "}
                    &nbsp;National ID
                  </h1>
                  <span class="dark:text-gray-100">{userID}</span>
                </div>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-phone"></i>
                    </span>{" "}
                    &nbsp;Phone
                  </h1>
                  <span class="dark:text-gray-100">+{mobile_no}</span>
                </div>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-envelope-open"></i>
                    </span>{" "}
                    &nbsp;Email
                  </h1>
                  <span class="dark:text-gray-100">{userEmail}</span>
                </div>
              </div>
            </div>

            <div className="flex-shrink max-w-full md:px-4 w-full  mb-6">
              <div className="relative mt-5 flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border ">
                <div className=" px-2 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                    <div class="flex items-center justify-between">
                      <h4 class="dark:text-white font-semibold my-3">
                        My Savings on {userSHG}
                      </h4>
                      <DownloadTableExcel
                        filename={`${userName} Savings on ${userSHG}`}
                        sheet="savings"
                        currentTableRef={tableRef.current}
                      >
                        <button
                          type="button"
                          class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                        >
                          Export Excel <i className="fas fa-download"></i>
                        </button>
                      </DownloadTableExcel>
                    </div>
                  </div>
                  <table
                    className="table-sorter table-bordered  w-full text-left text-gray-600 dark:text-gray-400"
                    ref={tableRef}
                  >
                    <thead>
                      <tr className="bg-gray-100  dark:bg-gray-900 dark:bg-opacity-40">
                        <th
                          data-sortable="false"
                          className="hidden lg:table-cell"
                        >
                          <input
                            id="check_all"
                            type="checkbox"
                            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                          />
                        </th>
                        <th className="hidden lg:table-cell">
                          {" "}
                          Transaction Code
                        </th>
                        <th className="hidden lg:table-cell"> Amount (KES) </th>
                        <th className="hidden lg:table-cell">Date added</th>
                        <th className="hidden lg:table-cell">Status</th>
                      </tr>
                    </thead>
                    <tbody class="flex-1 sm:flex-none">
                      {loading ? (
                        dataSavings
                      ) : (
                        <tr className="flex justify-center  items-center h-40 w-full">
                          <td className="w-full mx-auto ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="50"
                              height="50"
                              fill="currentColor"
                              className="bi bi-arrow-repeat animate-spin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                              <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                            </svg>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div class="bg-white dark:bg-gray-700 p-4 flex items-center flex-wrap">
                    <nav aria-label="Page navigation">
                      <ul class="inline-flex">
                        {pageSavings.map((page) => (
                          <li key={page}>
                            <button
                              onClick={() => pagination(page)}
                              className={
                                page === currentSavingsPage
                                  ? "h-10 px-5 text-white transition-colors duration-150 bg-green-600 border border-r-0 border-green-600 focus:shadow-outline"
                                  : "h-10 px-5 text-green-600 transition-colors duration-150 focus:shadow-outline hover:bg-green-100"
                              }
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default SecMemberView;
