import React, { useContext } from "react";

// Modify path,title, icons, subnav according to project
export const UserData = [
  {
    title: "Dashboard",
    path: "/userDashboard",
    icon: <i className="fa-solid fa-home"></i>,
  },

  {
    title: "Savings",
    path: "/userSaving",
    icon: <i className="fa-solid fa-sack-dollar"></i>,
  },
  {
    title: "User Shgs",
    path: "/userShg",
    icon: <i className="fa-solid fa-users"></i>,
  },
  // {
  //   title: "Select SHG",
  //   path: "/userSelectSHG",
  //   icon: <i className="fa-solid fa-user"></i>,
  // },
];
