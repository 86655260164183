import React from "react";

// Modify path,title, icons, subnav according to project
export const TresData = [
  {
    title: "Dashboard",
    path: "/tresDashboard",
    icon: <i className="fa-solid fa-home"></i>,
  },

  {
    title: "Chama Savings",
    path: "/tresShgSavings",
    icon: <i className="fa-solid fa-sack-dollar"></i>,
  },
  // {
  //   title: "Select SHG",
  //   path: "/tresSelectSHG",
  //   icon: <i className="fa-solid fa-users"></i>,
  // },
];
