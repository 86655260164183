import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Privacy() {
  const [open, setOpen] = useState(false);
  const newDate = new Date();
  const year = newDate.getFullYear();

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div
        className={`fixed bottom-5 right-5 z-50 transition-opacity duration-300 ease-in ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <button
          className="md:px-4 md:py-3 px-3 py-2 hover:scale-105 rounded-full bg-orange-500 text-white shadow hover:bg-orange-700"
          onClick={scrollToTop}
        >
          <span>
            <i className="fas fa-arrow-up"></i>
          </span>
        </button>
      </div>
      <header className="md:py-4 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 sticky top-0 z-40">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="shrink-0">
              <img
                className="w-auto h-9"
                src={require("../img/logod.png")}
                alt=""
              />
            </div>

            <div className="flex md:hidden">
              <button onClick={handleOpen} type="button" className="text-white">
                <span aria-hidden="true" className={`${open && "hidden"} px-1`}>
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </span>

                <span
                  aria-hidden="true"
                  className={`${!open && "hidden"} px-1`}
                >
                  <span className="w-7 h-7">
                    <i className="fas fa-close"></i>
                  </span>
                </span>
              </button>
            </div>

            <nav className="hidden ml-10 mr-auto space-x-10 lg:ml-20 lg:space-x-12 md:flex md:items-center md:justify-start  ">
              <Link
                to="/"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>
            </nav>

            <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
              <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
              <Link
                to="/signIn"
                className="relative inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                role="button"
              >
                {" "}
                Sign In{" "}
              </Link>
            </div>
          </div>

          <nav className={`${!open && "hidden"} md:hidden`}>
            <div className="flex flex-col pt-8 pb-4 space-y-6">
              <Link
                to="/"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>
              <div className="relative inline-flex items-center justify-center group">
                <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                <Link
                  to="/signIn"
                  title=""
                  className="relative inline-flex items-center justify-center w-full px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                  role="button"
                >
                  {" "}
                  Sign In{" "}
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <main id="content" role="main">
        <div class="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
          <h1 class="text-2xl font-bold md:text-4xl dark:text-white">
            Privacy Policy
          </h1>
        </div>

        <div class="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto dark:text-slate-300">
          <div class="grid gap-4 md:gap-8">
            <p>Privacy Policy</p>
            <p>
              <strong>Introduction</strong>
            </p>
            <p>
              SaffeChama is a registered brand of Msimbo Integrated Technologies
              Limited. The website&rsquo;s name
              is&nbsp;www.SaffeChama.com&nbsp;(hereinafter referred to as
              the&nbsp;<strong>&ldquo;Site&rdquo;</strong>). The site includes
              its USSD codes, sub-domains, and its mobile optimized version. The
              Site is owned by&nbsp;Msimbo Integrated Technologies
              Limited&nbsp;a private limited Company registered under the
              Companies Act 2015, Laws of Kenya.
            </p>
            <p>
              This privacy statement describes the way in which we store and use
              your personal data that we collect on our website, USSD codes and
              Mobile Apps.
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SaffeChama is a digital
              savings and loans mobilizer targeting the underbanked formally and
              informally formed groups or chamas who find themselves outside the
              contemporary banking system. It provides a digital platform to
              enhance table banking, merry-go-rounds or trade groups operations
              which are limited in scope.
            </p>
            <p>&nbsp;</p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SaffeChama website, USSD
              and Mobile App (referred to as &ldquo;platforms&rdquo;) greatly
              simplifies the process of group and individual savings and loaning
              process by allowing you to save and borrow amounts according to
              your group, Saccco or microfinance institution credit policy that
              you qualify for, within the allowed limits. SaffeChama platforms
              use your data as contained in your device and SaffeChama platform
              algorithms to identify you, verify your identity, assess your
              creditworthiness, credit risk, create a credit score for you and
              to comply with applicable laws, regulations, and rules, such as
              those relating to KYC (know-your-customer) and anti-money
              laundering requirements, also to analyse your behaviour and to
              detect and prevent fraud and other illegal uses of our platforms.
            </p>
            <p>
              <strong>Consent</strong>
              <br />
              Please be informed that by logging in, dialling SaffeChama USSD
              codes and downloading SaffeChama App you consent to the following:
            </p>
            <p>
              <br />
              <em>
                &ldquo;By logging in, dialing SaffeChama USSD, and downloading
                SaffeChama App, I hereby give consent to the collection and
                processing of my personal information for legitimate business
                purposes, included but not limited to determining my credit
                score and providing a loan.
                <br />I hereby certify that all the provided information are
                true and correct to the best of my knowledge, and that I will
                immediately notify SaffeChama of any inaccuracies in the data
                provided.
                <br />
                At the same time I authorize SaffeChama to verify and
                investigate the above statements and provided information. For
                this purpose, I consent to the processing of any personal
                information and records relating to me that might be obtained
                from third parties, including government agencies, employer,
                credit bureaus, business associates and other entities you may
                deem proper and sufficient in the conduct of the proper
                verification process.&rdquo;
              </em>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Definitions</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>Compliance Obligations</strong>&nbsp;means SaffeChama
              legal obligation imposed by the local and international law,
              internal policies, or procedures.
            </p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Consumer</strong>
              &nbsp;means any natural person with its resident at territory of
              Republic of Kenya to which SaffeChama provides its products or
              services.
            </p>
            <p>
              3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>Consumer Information</strong>&nbsp;means Consumer Personal
              Data, confidential information, tax information, information
              concerning the Consumer, Consumer&rsquo;s transactions, use of
              SaffeChama products and services and history relation with
              SaffeChama.
            </p>
            <p>
              4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>Personal Data&nbsp;</strong>means any information
              concerning the individual who can be identified, directly or
              indirectly, by reference to an identifier such as a name, an
              identification number, location data, an online identifier or to
              one or more factors specific to the physical, physiological,
              genetic, mental, economic etc. whether recorded in a material form
              or not.
            </p>
            <p>
              5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>Policy&nbsp;</strong>means this Privacy Policy of
              SaffeChama.
            </p>
            <p>
              6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>Sensitive Consumer Personal Data&nbsp;</strong>means data
              revealing the natural person&rsquo;s race, health status, ethnic
              social origin, conscience, belief, genetic data, biometric data,
              property details, marital status, family details including names
              of the person&rsquo;s children, parents, spouse or spouses, sex,
              or the sexual orientation.
            </p>
            <p>
              7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>Services&nbsp;</strong>means services offered by
              SaffeChama via SaffeChama.com, USSD code and Mobile App during the
              relation established between SaffeChama and the Customer.
            </p>
            <p>
              8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>SaffeChama-&nbsp;</strong>means Social Association for
              Financing Enterprise.
            </p>
            <p>
              9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <strong>SaffeChama USSD- </strong>means Unstructured Supplementary
              Service Data. SaffeChama uses USSD for prepaid call-back service,
              mobile-money services, location-based content services, and
              menu-based information services.
            </p>
            <p>
              10.&nbsp;&nbsp; <strong>SaffeChama App-&nbsp;</strong>means
              SaffeChama&rsquo;s mobile application available on our site or
              hosted on the Google Play Store.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Consumer Information that we collect.</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your privacy and personal
              information are of utmost concern to us. We consider your privacy
              to be very important and would never share your personal
              information without your explicit consent and in any unlawful
              manner.
            </p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We use your personal data
              to provide our Services, improve the quality of Services and
              products offered, send notifications, offers and promotional
              materials, protect our rights and interests as well as the rights
              and interests of third parties, and follow generally applicable
              laws.
            </p>
            <p>
              3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may collect, use and
              share Consumer Information. Consumer Information will be requested
              directly by SaffeChama, or they may be collected from you
              directly, from the person acting on your behalf, from other
              relevant sources include non public and public available
              information and they may be combined or generated with other
              information available to SaffeChama or any SaffeChama members
              Group.
            </p>
            <p>
              4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please be informed that we
              may collect the following Consumer Information:
            </p>
            <p>• information received directly from you.</p>
            <p>
              • provided by filling our registration forms which are integral
              part of using our Services via SaffeChama website, USSD and App.
            </p>
            <p>
              • provided during corresponding with us by chat, e-mail, phone,
              SMS, USSD messages.
            </p>
            <p>
              • provided when you enter a competition, promotion, surveys and
              reporting any technical issues related to the SaffeChama App or
              our Services.
            </p>
            <p>
              • participate in discussion groups when you use social media as
              Facebook, Instagram,
            </p>
            <p>
              • communicate with us using the above-mentioned methods with any
              other reason than mentioned above.
            </p>
            <p>
              • including but not limited to your name, surname, address, e-mail
              address, phone number, mobile phone number, your ID data,
              password, financial or credit information, personal description or
              photography, employer name and address, date of birth, sex,
              marital status, employment status, work phone number, monthly
              income and expenses, content of your SMS saved on your mobile,
              bank account number, transactions on bank accounts, description,
              signature, localization, contact list and any other information
              you would provide to us.
            </p>
            <p>
              5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information that we are
              collecting about you and used devices:
            </p>
            <p>
              • concerning the technical details of the device through which you
              have opened our website or for which our SaffeChama App has been
              downloaded, including information of the kind of the devices (IMEI
              or serial number) that you use to connect with our website or
              SaffeChama App, data from internet service providers, IP address
              and we have also data about your login to your personal profile
              created in SaffeChama (which is equal to the mobile phone number),
              information about SIM card used in your device, mobile network
              information, operation system used by your device, the type of
              used browser, device location and time zone settings;
            </p>
            <p>
              • information stored on your device concerning contact list, call
              logs, SMS logs.
            </p>
            <p>
              • information of your use of SaffeChama App or your visits on our
              website including location data or weblogs.
            </p>
            <p>
              • localizations of your device. Please be advice that we can use
              GPS technology or other location services to determinate your
              localization during using SaffeChama App. We must be sure that you
              are using SaffeChama App from the territory of Kenya and at the
              same it will allow us to prevent eventual frauds or improper use
              of our Services and to fulfill obligation arising from the
              anti-money laundering regulation.
            </p>
            <p>
              • information obtained from the third parties. SaffeChama has a
              right to cooperate with the external partners including but not
              limited to the credit reference bureaus, mobile network providers
              and collection agencies. We put our efforts to limit the number of
              such partners to the necessary minimum.
            </p>
            <p>
              6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SaffeChama unique
              application numbers which are generated during installation or
              uninstallation of our SaffeChama App.
            </p>
            <p>
              7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please be informed that we
              may from time to time send you push/SMS notifications concerning
              marketing messages or other information related to our Services or
              transactions. You have the right to opt-out from receiving this
              type of notification at any time by following instructions.
            </p>
            <p>
              8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Moreover, we gather some
              information automatically when you visit our website, or you
              download SaffeChama App via our gateway. Please consider that
              these kinds of data are mostly collected by cookie files and
              tracking of navigation signals, they can also include internet
              protocol (IP) address, browser type, internet service provider,
              referring and exit pages, operating system, clickstreams data,
              data, and time stamp. Please note that we can also combine the
              collected data to other information that we already obtained to
              supplied analyses, security, content generation and marketing.
            </p>
            <p>
              9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We are also using the
              analytics tools and software to develop and analyse use of our
              SaffeChama App and our services. We may use from time-to-time
              support of analytics companies and grand them access anonymous
              individual data to analyse and present the best solutions from
              user experience perspective how the offered Services are used.
            </p>
            <p>
              10.&nbsp;&nbsp; Please be informed that we use a tool that allows
              us to make automatic decisions. Making decisions in an automated
              way is the ability to make decisions using technological tools
              without human involvement. We use the above technology to reduce
              the risk of making a mistake, lowering the risk of non-payment of
              a loan, and most importantly, it allows a credit decision to be
              made in a shorter period.
            </p>
            <p>
              11.&nbsp;&nbsp; Decisions that are not fully automatic are those
              that may include the actions of our employees. For instance,
              before we grant you a loan, we will investigate your
              creditworthiness and, in some cases, we may perform added phone
              verification, which takes place before making a credit decision.
            </p>
            <p>
              12.&nbsp;&nbsp; When you will uninstall SaffeChama App, you can
              also withdraw your consents from accessing your Consumer
              Information in the future. However please note that we may keep
              and share stored Consumer Information even after when you
              uninstalled SaffeChama App for as long as necessary for the
              fulfillment of the purposes for which the Consumer Information was
              received or for the legitimate data controller purposes, exercise
              or defence of legal claims, statistics or as allowed by law.
            </p>
            <p>
              13.&nbsp;&nbsp; Please be also informed that we can record our
              telephone conversations for training purposes, in connection with
              processing complaints, for evidentiary purposes, and to verify
              customer service transactions.
            </p>
            <p>
              <strong>Purposes of data collection</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SaffeChama collect Consumer
              Information including but not limited for the following purposes:
            </p>
            <p>• to verify your identity,</p>
            <p>• to assess credit risk,</p>
            <p>
              • to check our database for duplicate occurrences of your data
              (blacklist),
            </p>
            <p>
              • to follow applicable laws, regulation, guidelines, rules such as
              those relating to &ldquo;Know Your Customer&rdquo; obligations,
            </p>
            <p>
              • to perform obligation concerning anti money laundering checks,
            </p>
            <p>
              • to detect, prevent frauds and any other illegal uses of the
              Services or SaffeChama App,
            </p>
            <p>• to process the transactions,</p>
            <p>• to provide you with personalized services,</p>
            <p>
              • to disburse loans and collect payments related to the offered
              Services,
            </p>
            <p>• to build the credit models and perform credit scoring,</p>
            <p>• to analyse your behaviours,</p>
            <p>
              • to localize the technical issues with our SaffeChama App and
              Services,
            </p>
            <p>
              • to contact with you through distance communication channels like
              phones, SMS, WhatsApp, or e-mail messages,
            </p>
            <p>
              • to present you marketing content and promotional offers
              concerning our Services,
            </p>
            <p>
              • to check our database during logging to our SaffeChama App,
              whether used mobile phone number already exist in our database,
            </p>
            <p>• to analyse your behaviours,</p>
            <p>• to allow our partners to fulfill their obligations to you,</p>
            <p>• to allow us to fulfil our obligations to our partners.</p>
            <p>
              <strong>Sharing Consumer Information</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You will find here
              information concerning the sharing data process.
            </p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The information collected
              by us shall be used for the purposes indicated at Clause 5 of the
              Policy.
            </p>
            <p>
              3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may share Consumer
              Information with a limited number of our partners for research and
              development. We may also provide anonymous Consumer Information
              for our external partners for the purposes of compiling statistics
              relating to our user&rsquo;s base, loan portfolio, analysis.
            </p>
            <p>
              4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When you use our SaffeChama
              App and Services you agree that we may as it is necessary and
              proper share, transfer and show Consumer Information to the
              following recipients:
            </p>
            <p>
              • to members of SaffeChama Group for internal administrative
              purposes or for services necessary to make a credit decision,
              sub-contractors, service providers or agents of SaffeChama.
            </p>
            <p>
              • any credit reference bureaus or agencies and any financial
              institutions to obtain information concerning credit references,
            </p>
            <p>
              • to persons acting on your behalf, payment recipients,
              beneficiaries, account nominees, intermediary, stock exchanges,
            </p>
            <p>
              • to any third party to whom we provide introductions or
              referrals.
            </p>
            <p>
              • to third party services providers which has concluded relevant
              contract with SaffeChama that support us in our business
              operations in particular in marketing, identification of your
              identity, fraud prevention, anti money laundering and transaction
              processing, specialized in analytical tools or providers of
              information search services, hosting and website development, risk
              assessment, debt recovery and customer service. We will share the
              data only when it is necessary to appropriate perform our legal
              obligations and the proper performance of the Services.
            </p>
            <p>
              • government officials, law enforcement or other third parties,
              but only in relation to a formal request, court order or a similar
              legal procedure or when we assume that the disclosure is necessary
              to follow the law, prevent physical arms, financial losses, report
              suspected illegal activity or to investigate violations of our
              Services.
            </p>
            <p>• or any other legally permissible purposes.</p>
            <p>&nbsp;</p>
            <p>
              5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We can transfer your
              personal data:
            </p>
            <p>
              • at any time when we are legally obliged to do; in particular we
              may disclose information about using our services and your visit
              to our websites and using SaffeChama App in order to ensure
              compliance with the law, when we can have reasonable suspicion
              that these disclosure is necessary to protect our rights, protect
              your safety or the safety of others, prevent and detecting fraud
              or responding to requests from state institutions;
            </p>
            <p>
              • when it is in connection with the personal identification,
              detection and prevention of fraud and protection against fraud and
              to reduce credit risk and prevention of money laundering
              activities and the financing of terrorist activities.
            </p>
            <p>
              • for credit reference bureaus and credit intermediaries,
              competent national authorities, and non-governmental
              organizations, as well as other third parties to supply a full
              assessment of your creditworthiness.
            </p>
            <p>
              • when executing a direct debit or other payment due under the
              loan agreement you have entered with you; then we may share your
              personal data with external suppliers providing payment processing
              services.
            </p>
            <p>
              • in case of non-performance or improper performance of your
              obligations arising from concluded loan agreement, we may transfer
              data about your overdue liabilities to debt collection companies,
              credit reference bureaus and companies supplying legal services.
            </p>
            <p>
              • we may send your personal data to the analysts and service
              providers who supplies web browsers services, who help us improve
              and perfect our application and/or website.
            </p>
            <p>
              • during sells all or part of our business or transferring our
              receivables to a third party.
            </p>
            <p>
              6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your personal data will be
              processed, stored, and transferred to third parties by the
              channels shown in this Policy, in the agreements between you and
              SaffeChama, and in the consents granted by you.
            </p>
            <p>
              7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may search your data,
              information about you, your creditworthiness, your financial
              situation including your incomes and your debts, information about
              your employment, in credit references bureaus, publicly available
              sources, other suppliers providing such information.
            </p>
            <p>
              8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may associate any
              category of information with any other category of information and
              will treat the combined information as Consumer Information in
              accordance with this Policy for as long as it is combined.
            </p>
            <p>
              9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree that SaffeChama
              shall not be liable for any loss or damage arising from or
              incidental to our use, collection, processing and sharing of
              information relating to you, and any action we have taken in
              relation to this section.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Consumer obligations and rights</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please be informed that you
              have right to:
            </p>
            <p>
              • request to correct your data or limit the processing of your
              personal data and,
            </p>
            <p>• request access to your personal data.</p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree to inform us
              promptly, and in any event, within 30 days, via e-mail:&nbsp;
              <span className="text-sky-500">saffechama@msimboit.net</span>
              &nbsp;or by calling to our Infoline: +254 716 003792 if there are
              any changes to your data supplied to us from time to time, and to
              respond promptly to any request from us.
            </p>
            <p>
              3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please note that when you
              fail to provide promptly Consumer Information that are necessary
              to use our SaffeChama App or Services or you withhold or withdraw
              any consents that are necessary to process, transfer or disclose
              your Consumer Information in accordance to the Clause 5, or we may
              have a suspicions regarding any illegal use of SaffeChama App or
              our Services or any fraud or any financial crime we may not be
              entitled to continue our Services and we reserve the right to
              terminate our relationship with you. We can also take actions that
              are necessary by our internal policies and Compliance Obligations.
              Moreover, we can block, transfer, or close your accounts where it
              is allowed under local law.
            </p>
            <p>
              4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Where we have given you or
              where you have chosen a password or PIN that enables you to access
              certain parts of our SaffeChama App, you are responsible for
              keeping this password confidential. You are requested to not share
              a password or login with anyone.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Data Controller</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SaffeChama is controller of
              your data. We respect your privacy and ensure protection of your
              personal data and the processing of your personal data following
              the law.
            </p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The data that we collect
              from you may be transferred to, and stored at, a destination
              outside Kenya. Thus, by sending your Consumer Information you
              agree to this transfer, storing or processing of data.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Retention</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We store your data for the
              time necessary to perform the loan agreement, perform our
              legitimate interests, following the statutory data retention
              period requirements.
            </p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Processing of the obtained
              data by the SaffeChama despite his/her request to remove the data
              is lawful, where this is needed in particular for SaffeChama to
              meet its legal obligation, for statistical purposes, or the
              establishment, exercise, or defence of legal claims.
            </p>
            <p>
              3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your data is processed to
              maintain your SaffeChama App account, we process this data for a
              period providing services to you by electronic means in accordance
              with the provisions of the Policy.
            </p>
            <p>
              4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your data regarding your
              identity, as well as the content of the concluded loan agreement
              and its performance, and data processing for assessing
              creditworthiness and credit risk will be kept for a period equal
              to the period of limitation of any resulting claims from the
              concluded loan agreement.
            </p>
            <p>
              5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We process your data to
              send you the commercial information regarding SaffeChama products
              and SaffeChama partners until you object to their processing.
            </p>
            <p>
              6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In terms of the basis of
              the processing of your data is your consent, we process your data
              that are covered by the consent to the moment of its withdrawal or
              expiry (if the consent has been limited by the term).
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Contact</strong>
            </p>
            <p>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Regarding privacy point of
              contact you shall write an e-mail:&nbsp;{" "}
              <span className="text-sky-500">saffechama@msimboit.net</span>
              &nbsp;or you can call our Infoline: +254 716 003 792 (cost of the
              call is in line with your operator&rsquo;s tariff).
            </p>
            <p>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Developer&rsquo;s
              information:{" "}
              <a className="text-sky-500" href="mailto:saffechama@msimboit.net">
                saffechama@msimboit.net
              </a>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Final provisions</strong>
            </p>
            <p>
              We may update our Policy from time to time. Whenever we make a
              change, we will post the updated Policy at our website or
              SaffeChama App when you next start the SaffeChama App. We
              encourage you to check our Policy periodically. The new terms may
              be displayed on-screen, and you may be required to read and accept
              them to continue your use of the web, USSD and Mobile App or the
              services. This Privacy policy was last updated on&nbsp;
              <strong>1 May 2023.</strong>
            </p>
          </div>
        </div>
      </main>
      <section className="py-4 bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 sm:pt-6 lg:pt-6">
        <footer className="bg-white/10 rounded-lg shadow m-4 ">
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-100 sm:text-center ">
              © {year} All Rights Reserved, Msimbo DevOps.
            </span>
            <span className="text-sm flex items-center text-gray-100 sm:text-center ">
              <span className="mr-2">Powered by </span>
              <a href="https://sasapay.co.ke/" target="_blank" rel="noreferrer">
                <img
                  src={require("../img/sasapay.png")}
                  alt="lg"
                  className="w-24"
                />
              </a>
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-100  sm:mt-0">
              <li>
                <Link to="/" className="mr-4 hover:underline  ">
                  <span className="mr-1">Home</span> |
                </Link>
              </li>

              <li>
                <Link to="/faq" className="mr-4 hover:underline  ">
                  <span className="mr-1">FAQs</span> |
                </Link>
              </li>

              <li>
                <Link className="mr-4 hover:underline" to="/terms">
                  <span className="mr-1">Terms and Conditions</span>|
                </Link>
              </li>
              <li>
                <Link className=" mr-4 hover:underline" to="/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </section>
    </>
  );
}

export default Privacy;
