import Layout from "./components/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { authContext } from "./components/Context/AuthContext";
import Dashboard from "./components/pages/Dashboard";
import Profile from "./components/pages/Profile";
import Login from "./components/login/Login";
import Sgh from "./components/pages/Sgh";
import User from "./components/pages/User";
import { useState } from "react";
import UserCreate from "./components/pages/UserCreate";
import Protected from "./components/ProtectedRoutes/Protected";
import Unauthorized from "./components/pages/Unauthorized";
import Page404 from "./components/pages/Page404";
import ChairDashboard from "./components/pages/chairman/ChairDashboard";
import ChairProfile from "./components/pages/chairman/ChairProfile";
import ChairMembers from "./components/pages/chairman/ChairMembers";
import SecDashboard from "./components/pages/secretary/SecDashboard";
import SecMembers from "./components/pages/secretary/SecMembers";
import SecCreateMember from "./components/pages/secretary/SecCreateMember";
import SecProfile from "./components/pages/secretary/SecProfile";
import TresDashboard from "./components/pages/treasurer/TresDashboard";
import TresProfile from "./components/pages/treasurer/TresProfile";
import AffDashboard from "./components/pages/affiliate/AffDashboard";
import AffProfile from "./components/pages/affiliate/AffProfile";
import AffShg from "./components/pages/affiliate/AffShg";
import AffCreateShg from "./components/pages/affiliate/AffCreateShg";
import Home from "./components/pages/Home";
import AffUserLinkSgh from "./components/pages/affiliate/AffUserLinkSgh";
import LinkUserShg from "./components/pages/affiliate/LinkUserShg";
import ViewUserShg from "./components/pages/affiliate/ViewUserShg";
import UserDashboard from "./components/pages/user/UserDashboard";
import UserProfile from "./components/pages/user/UserProfile";
import UserSavings from "./components/pages/user/UserSavings";
import UserShg from "./components/pages/user/UserShg";
import UserShgView from "./components/pages/user/UserShgView";
import UserShgMemberView from "./components/pages/user/UserShgMemberView";
import AffShgView from "./components/pages/affiliate/AffShgView";
import AffShgMemberView from "./components/pages/affiliate/AffShgMemberView";
import AffShgSavings from "./components/pages/affiliate/AffShgSavings";
import AffShgViewSavings from "./components/pages/affiliate/AffShgViewSavings";
import ChairMemberView from "./components/pages/chairman/ChairMemberView";
import SecMemberView from "./components/pages/secretary/SecMemberView";
import TresShgSavings from "./components/pages/treasurer/TresShgSavings";
import UserSelectSHG from "./components/pages/user/UserSelectSHG";
import ShgView from "./components/pages/ShgView";
import AffCreateOfficial from "./components/pages/affiliate/AffCreateOfficial";
import ChairShgSavings from "./components/pages/chairman/ChairShgSavings";
import TresMemberView from "./components/pages/treasurer/TresMemberView";
import SecShgSavings from "./components/pages/secretary/SecShgSavings";
import ShgSavings from "./components/pages/ShgSavings";
import ShgViewSavings from "./components/pages/ShgViewSavings";
import UserView from "./components/pages/UserView";
import UserUpdate from "./components/pages/UserUpdate";
import AffUserUpdate from "./components/pages/affiliate/AffUserUpdate";
import ChairUserUpdate from "./components/pages/chairman/ChairUserUpdate";
import SecUpdateUser from "./components/pages/secretary/SecUpdateUser";
import TresUserUpdate from "./components/pages/treasurer/TresUserUpdate";
import AddShg from "./components/pages/AddShg";
import AllLoans from "./components/pages/AllLoans";
import PaidLoans from "./components/pages/PaidLoans";
import UnpaidLoans from "./components/pages/UnpaidLoans";
import AddMembers from "./components/pages/AddMembers";
import UserCreateAff from "./components/pages/UserCreateAff";
import CreateOfficial from "./components/pages/CreateOfficial";
import UserLinkShg from "./components/pages/UserLinkShg";
import UserShgs from "./components/pages/UserShgs";
import LinkUserToShg from "./components/pages/LinkUserToShg";
import ChairLoans from "./components/pages/chairman/ChairLoans";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";
import Faq from "./components/pages/Faq";
import CompanyAff from "./components/pages/CompanyAff";
import IndividualAff from "./components/pages/IndividualAff";

function App() {
  const [logged, setLogged] = useState(false);
  const [userName, setUserName] = useState(undefined);
  const [userMobile, setUserMobile] = useState(undefined);
  const [userID, setUserID] = useState(undefined);
  const [userRole, setUserRole] = useState(undefined);
  const [userEmail, setUserEmail] = useState(undefined);
  return (
    <authContext.Provider
      value={{
        logged,
        setLogged,
        userName,
        setUserName,
        userMobile,
        setUserMobile,
        userID,
        setUserID,
        userRole,
        setUserRole,
        userEmail,
        setUserEmail,
      }}
    >
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/index" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/signIn" element={<Login />} />
            <Route element={<Protected roles="admin" />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/sgh" element={<Sgh />} />
              <Route path="/sghView/:accountname" element={<ShgView />} />
              <Route path="/user" element={<User />} />
              <Route path="/userView/:mobile_no" element={<UserView />} />
              <Route path="/userUpdate/:UserMobile" element={<UserUpdate />} />
              <Route path="/shgSavings" element={<ShgSavings />} />
              <Route path="/addShg" element={<AddShg />} />
              <Route path="/allLoans" element={<AllLoans />} />
              <Route path="/paidLoans" element={<PaidLoans />} />
              <Route path="/unpaidLoans" element={<UnpaidLoans />} />
              <Route path="/addMembers" element={<AddMembers />} />
              <Route path="/companyAff" element={<CompanyAff />} />
              <Route path="/individualAff" element={<IndividualAff />} />
              <Route
                path="/shgViewSavings/:account_name"
                element={<ShgViewSavings />}
              />
              <Route path="/userCreate" element={<UserCreate />} />
              <Route path="/userLinkShg" element={<UserLinkShg />} />
              <Route path="/userShgs/:mobile_no" element={<UserShgs />} />
              <Route
                path="/linkUserToShg/:mobile_no"
                element={<LinkUserToShg />}
              />
              <Route path="/userCreateAff" element={<UserCreateAff />} />
              <Route
                path="/createOfficial/:account_name"
                element={<CreateOfficial />}
              />
            </Route>
            <Route element={<Protected roles="chairman" />}>
              <Route path="/chairDashboard" element={<ChairDashboard />} />
              <Route path="/chairProfile" element={<ChairProfile />} />
              <Route path="/chairShgSavings" element={<ChairShgSavings />} />
              <Route path="/chairMembers" element={<ChairMembers />} />
              <Route path="/chairLoans" element={<ChairLoans />} />
              <Route
                path="/chairUserUpdate/:UserMobile"
                element={<ChairUserUpdate />}
              />
              <Route
                path="/chairMemberView/:mobile_no"
                element={<ChairMemberView />}
              />
            </Route>
            <Route element={<Protected roles="secretary" />}>
              <Route path="/secDashboard" element={<SecDashboard />} />
              <Route path="/secProfile" element={<SecProfile />} />
              <Route path="/secMembers" element={<SecMembers />} />
              <Route path="/secShgSavings" element={<SecShgSavings />} />
              <Route path="/secCreateMember" element={<SecCreateMember />} />
              <Route
                path="/secUserUpdate/:UserMobile"
                element={<SecUpdateUser />}
              />
              <Route
                path="/secMemberView/:mobile_no"
                element={<SecMemberView />}
              />
            </Route>
            <Route element={<Protected roles="treasurer" />}>
              <Route path="/tresDashboard" element={<TresDashboard />} />
              <Route path="/tresProfile" element={<TresProfile />} />
              <Route path="/tresShgSavings" element={<TresShgSavings />} />
              <Route
                path="/tresUserUpdate/:UserMobile"
                element={<TresUserUpdate />}
              />
              <Route
                path="/tresMemberView/:mobile_no"
                element={<TresMemberView />}
              />
            </Route>

            <Route element={<Protected roles="affiliate" />}>
              <Route path="/affDashboard" element={<AffDashboard />} />
              <Route path="/affProfile" element={<AffProfile />} />
              <Route path="/affShg" element={<AffShg />} />
              <Route path="/affCreateShg" element={<AffCreateShg />} />
              <Route
                path="/affCreateOfficial/:accountname"
                element={<AffCreateOfficial />}
              />
              <Route path="/affUserLinkShg" element={<AffUserLinkSgh />} />
              <Route path="/linkUserShg/:mobile_no" element={<LinkUserShg />} />
              <Route path="/viewUserShg/:mobile_no" element={<ViewUserShg />} />
              <Route path="/affShgView/:accountname" element={<AffShgView />} />
              <Route path="/affShgSavings" element={<AffShgSavings />} />
              <Route
                path="/affuserUpdate/:UserMobile"
                element={<AffUserUpdate />}
              />
              <Route
                path="/affShgViewSavings/:account_name"
                element={<AffShgViewSavings />}
              />

              <Route
                path="/affShgMemberView/:mobile_no"
                element={<AffShgMemberView />}
              />
            </Route>
            <Route element={<Protected roles="user" />}>
              <Route path="/userDashboard" element={<UserDashboard />} />
              <Route path="/userProfile" element={<UserProfile />} />
              <Route path="/userShg" element={<UserShg />} />
              <Route path="/userSaving" element={<UserSavings />} />
              <Route
                path="/userShgView/:account_name"
                element={<UserShgView />}
              />
              <Route
                path="/userShgMemberView/:mobile_no"
                element={<UserShgMemberView />}
              />
            </Route>
            <Route path="/userSelectSHG" element={<UserSelectSHG />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </authContext.Provider>
  );
}

export default App;
