import React from "react";

function Unauthorized() {
  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden mx-2 md:mx-0">
        <div className="w-full  m-auto bg-white dark:bg-slate-800/60 rounded shadow-lg ring-2 ring-slate-300/50 dark:ring-slate-700/50 lg:max-w-md">
          <div className="text-center p-6 bg-slate-900 rounded-t">
            <img
              src={require("../img/logod.png")}
              alt="logo"
              className="w-full h-18 mx-auto mb-2"
            />
          </div>

          <form className="p-6">
            <div className="text-center">
              <img
                src={require("../img/404-computer.png")}
                alt=""
                className=" w-52  h-52 block mx-auto my-6"
              />
              <h1 className="font-bold text-3xl dark:text-slate-200">
                Unauthorized!
              </h1>
              <h5 className="font-medium text-lg text-slate-400">
                Error, Cannot Access Page
              </h5>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Unauthorized;
