import React from "react";

// Modify path,title, icons, subnav according to project
export const AffData = [
  {
    title: "Dashboard",
    path: "/affDashboard",
    icon: <i className="fa-solid fa-home"></i>,
  },
  {
    title: "SHGs",
    path: "#",
    icon: <i className="fa-solid fa-bookmark"></i>,
    subNav: [
      {
        title: "All SHGs",
        path: "/affShg",
        icon: <i className="fa-solid fa-users"></i>,
      },
      {
        title: " SHGs Savings",
        path: "/affShgSavings",
        icon: <i className="fa-solid fa-users"></i>,
      },
    ],
  },
  // {
  //   title: "Users",
  //   path: "#",
  //   icon: <i className="fa-solid fa-user"></i>,
  //   subNav: [
  //     {
  //       title: "All Users ",
  //       path: "/affUserLinkShg",
  //       icon: <i className="fa-solid fa-users"></i>,
  //     },
  //   ],
  // },
];
