import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

function AffCreateOfficial() {
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const { accountname } = useParams();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile_no, setMobile] = useState("");
  const [id_no, setIDNo] = useState("");
  const [role, setRole] = useState("chairman");
  const [pobox, setPobox] = useState("");
  const [kra, setKRA] = useState("");
  const [county, setCounty] = useState("");
  const [sub_county, setSubCounty] = useState("");
  const [network_code, setNetworkCode] = useState("Mpesa");
  const [cpassword, setCPassword] = useState("");
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [email, setEmail] = useState("");

  const postData = (e) => {
    e.preventDefault();

    if (
      mobile_no &&
      id_no &&
      kra &&
      network_code &&
      pobox &&
      county &&
      sub_county &&
      name &&
      email &&
      role &&
      password &&
      cpassword
    ) {
      setLoading(true);
      Axios.post("https://us-east4-safechama.cloudfunctions.net/Signup", {
        message: {
          access_token: token,
          mobile_no: mobile_no,
          affiliates_type: "",
          id_no: id_no,
          company_name: "",
          company_tel: "",
          reg_no: "",
          name: name,
          email: email,
          role: role,
          NetworkCode: network_code,
          password: password,
          cpassword: cpassword,
          kra_pin: kra,
          po_box: pobox,
          county: county,
          sub_county: sub_county,
          address: "",
        },
      })
        .then((res) => {
          //   console.log("Posting Data From::", res.data);

          if (res.data.code === 555) {
            Axios.post(
              "https://us-central1-safechama.cloudfunctions.net/linkmembertoaccount",
              {
                message: {
                  access_token: token,
                  mobile_no: mobile_no,
                  accounts: [accountname],
                  role: role,
                },
              }
            )
              .then((res) => {
                setLoading(false);
                // console.log("Posting SHG Link Data From::", res.data.response);
                alert(res.data.response);
                navigate(`/affShgView/${accountname}`);
              })
              .catch((err) => console.log(err));
          } else if (res.data.code === 606) {
            setLoading(false);
            setuserErr(true);
          } else if (res.data.code === 505) {
            setLoading(false);
            setpasswordErr(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    } else {
      setallErr(true);
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && "hidden"
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main className="pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full md:px-4 w-full mb-6">
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="flex flex-wrap flex-row -mx-4">
                  <div className="flex-shrink max-w-full px-4 w-full">
                    <div className="w-full mb-6 overflow-x-auto">
                      <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                        <div className="flex items-center justify-between">
                          <h4 className="dark:text-white font-semibold my-3">
                            Add Member Manually
                          </h4>
                        </div>
                      </div>

                      <div
                        className={`${
                          !allErr && "hidden"
                        } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-orange-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">
                              Error Occured While Registering
                            </p>
                            <p className="text-sm">* All Fields are Required</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          !userErr && "hidden"
                        } bg-yellow-100 my-5    border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md`}
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-yellow-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">
                              Error Occured While Registering
                            </p>
                            <p className="text-sm">User Mobile No. Exists</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          !passwordErr && "hidden"
                        } bg-red-100 my-5    border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md`}
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-red-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">
                              Error Occured While Registering
                            </p>
                            <p className="text-sm">password don't match</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          !allErr && "hidden"
                        } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-orange-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">
                              Error Occured While Registering
                            </p>
                            <p className="text-sm">* All Fields are Required</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          !userErr && "hidden"
                        } bg-yellow-100 my-5    border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md`}
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-yellow-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">
                              Error Occured While Registering
                            </p>
                            <p className="text-sm">User Mobile No. Exists</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          !passwordErr && "hidden"
                        } bg-red-100 my-5    border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md`}
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-red-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">
                              Error Occured While Registering
                            </p>
                            <p className="text-sm">password don't match</p>
                          </div>
                        </div>
                      </div>

                      <hr className="block w-12 h-0.5 mx-auto my-5 bg-gray-700 border-gray-700" />
                      <form className="flex flex-wrap flex-row  ">
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            Mobile No.
                          </label>
                          <input
                            name="mobile"
                            id="inputmobile"
                            className={`${
                              userErr &&
                              " border-yellow-500 border text-yellow-900"
                            } w-full leading-5 border   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                            value={mobile_no}
                            aria-label="mobile"
                            type="text"
                            onChange={(e) => {
                              setMobile(e.target.value);
                              setuserErr(false);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-200">
                            Network Code
                          </label>

                          <select
                            name="affiliate"
                            id="affiliate"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={network_code}
                            aria-label="affiliate"
                            onChange={(e) => {
                              setNetworkCode(e.target.value);
                              setallErr(false);
                            }}
                          >
                            <option value="Mpesa">Mpesa</option>
                            <option value="AirtelMoney">AirtelMoney</option>
                            <option value="T-Kash">T-Kash</option>
                          </select>
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            ID No.
                          </label>
                          <input
                            name="idNo"
                            id="inputidNo"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={id_no}
                            aria-label="idNo"
                            type="text"
                            onChange={(e) => {
                              setIDNo(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            UserName
                          </label>
                          <input
                            name="name"
                            id="inputname"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={name}
                            aria-label="name"
                            type="name"
                            onChange={(e) => {
                              setName(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            Email
                          </label>
                          <input
                            name="email"
                            id="inputemail"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={email}
                            aria-label="email"
                            type="email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            Select User Role
                          </label>
                          <select
                            name="role"
                            id="inputrole"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={role}
                            aria-label="role"
                            onChange={(e) => {
                              setRole(e.target.value);
                              setallErr(false);
                            }}
                          >
                            <option value="chairman">Chairman</option>
                            <option value="secretary">Secretary</option>
                            <option value="treasurer">Treasurer</option>
                            <option value="user">Member</option>
                          </select>
                        </div>

                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            KRA PIN
                          </label>

                          <input
                            name="role"
                            id="inputkra"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={kra}
                            aria-label="kra"
                            type="text"
                            onChange={(e) => {
                              setKRA(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            P.O. Box
                          </label>

                          <input
                            name="pobox"
                            id="inputpobox"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={pobox}
                            aria-label="pobox"
                            type="text"
                            onChange={(e) => {
                              setPobox(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            County
                          </label>

                          <input
                            name="county"
                            id="inputcounty"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={county}
                            aria-label="county"
                            type="text"
                            onChange={(e) => {
                              setCounty(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                            Sub-County
                          </label>

                          <input
                            name="sub_county"
                            id="inputsub_county"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            value={sub_county}
                            aria-label="sub_county"
                            type="text"
                            onChange={(e) => {
                              setSubCounty(e.target.value);
                              setallErr(false);
                            }}
                          />
                        </div>

                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <div className="flex flex-wrap flex-row">
                            <div className="flex-shrink max-w-full w-1/2">
                              <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                                New PIN
                              </label>
                            </div>
                          </div>
                          <input
                            id="inputpass"
                            className={`${
                              passwordErr &&
                              " border-red-500 border text-red-900"
                            } w-full leading-5 border  relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                            aria-label="password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setpasswordErr(false);
                              setallErr(false);
                            }}
                          />
                        </div>
                        <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <div className="flex flex-wrap flex-row">
                            <div className="flex-shrink max-w-full w-1/2">
                              <label className="inline-block  mb-2 text-gray-700 dark:text-gray-200">
                                Confirm PIN
                              </label>
                            </div>
                          </div>
                          <input
                            id="inputcpass"
                            className={`${
                              passwordErr &&
                              " border-red-500 border text-red-900"
                            } w-full leading-5 border  relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                            aria-label="cpassword"
                            type="password"
                            value={cpassword}
                            onChange={(e) => {
                              setCPassword(e.target.value);
                              setpasswordErr(false);
                              setallErr(false);
                            }}
                          />
                        </div>

                        <div className="flex-shrink max-w-full mt-4 xl:mt-0 px-4 w-full">
                          <button
                            onClick={postData}
                            className="py-2 px-4 inline-block text-center rounded leading-5 text-gray-100 bg-green-500 border border-green-500 hover:text-white hover:bg-green-600 hover:ring-0 hover:border-green-600 focus:bg-green-600 focus:border-green-600 focus:outline-none focus:ring-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              className="inline-block w-4 h-4 mr-2 bi bi-box-arrow-in-right"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                              <path d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                            </svg>
                            Create Member
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AffCreateOfficial;
