import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

function UserShgView() {
  const { account_name } = useParams();
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("userToken");

  const navigate = useNavigate();

  useEffect(() => {
    checkSHG();
  }, []);

  const checkSHG = () => {
    if (!localStorage.getItem("userSHG")) {
      navigate("/userSelectSHG");
    }
  };

  useEffect(() => {
    Axios.post(
      "https://us-east4-safechama.cloudfunctions.net/getallmembersinSHG",
      { message: { access_token: token, account_name: account_name } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting Users Data ::", res.data.data);
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const myuser = Object.values(users);
  const dataUsers = myuser.map((data, index) => {
    return (
      <tr key={index} className="border-b dark:border-gray-700">
        <td>
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
          />
        </td>
        <td>
          <div className="flex flex-wrap flex-row items-center">
            <img
              className="h-8 w-8 rounded-full"
              src={require("../../img/avatar8.png")}
              alt="profile"
            />
          </div>
        </td>
        <td className="hidden sm:table-cell">{data.name}</td>
        <td className="hidden sm:table-cell">{data.role}</td>
        <td className="hidden sm:table-cell">{data.account_name}</td>
        <td className="hidden sm:table-cell">{data.mobile_no}</td>
        <td className="hidden lg:table-cell">13/12/2022</td>
        <td className="hidden lg:table-cell">
          <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
            Active
          </span>
        </td>

        <td className="text-center">
          <Link
            to={`/userShgMemberView/${data.mobile_no}`}
            className="text-sm px-2 mx-3 py-1 font-semibold  text-white bg-green-500 rounded-md"
            title="link"
          >
            View Member
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <>
      <main className="pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                All {account_name} Members
              </p>
            </div>
            <div className="flex-shrink max-w-full px-4 w-full mb-6">
              <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="flex flex-wrap flex-row -mx-4">
                  <div className="flex-shrink max-w-full px-4 w-full">
                    <div className="w-full mb-6 overflow-x-auto">
                      <table className="table-sorter table-bordered w-full text-left text-gray-600 dark:text-gray-400">
                        <thead>
                          <tr className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-40">
                            <th data-sortable="false">
                              <input
                                id="check_all"
                                type="checkbox"
                                className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                              />
                            </th>
                            <th>Image</th>
                            <th>Username</th>
                            <th>Role</th>
                            <th className="hidden sm:table-cell">SHG Name</th>
                            <th className="text-center hidden lg:table-cell">
                              Contacts
                            </th>
                            <th className="hidden lg:table-cell">Date added</th>
                            <th className="hidden lg:table-cell">Status</th>
                            <th data-sortable="false">Actions</th>
                          </tr>
                        </thead>
                        <tbody>{dataUsers}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserShgView;
