import React from "react";

// Modify path,title, icons, subnav according to project
export const ChairData = [
  {
    title: "Dashboard",
    path: "/chairDashboard",
    icon: <i className="fa-solid fa-home"></i>,
  },

  {
    title: "Chama Members",
    path: "/chairMembers",
    icon: <i className="fa-solid fa-user"></i>,
  },

  {
    title: "Chama Savings",
    path: "/chairShgSavings",
    icon: <i className="fa-solid fa-sack-dollar"></i>,
  },

  {
    title: "Chama Loans",
    path: "/chairLoans",
    icon: <i className="fa-solid fa-hand-holding-usd"></i>,
  },
];
