import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import * as XLSX from "xlsx"; // Import the xlsx library

function Sgh() {
  const shgRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("userToken");

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;
  const [currentUsers, setCurrentUsers] = useState([]);

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/getallselfhelpgroupaccount",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        const shgArray = Object.values(res.data.data);
        setUsers(shgArray);
        setFilteredUsers(shgArray);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  // Filtering the users based on search query
  useEffect(() => {
    // When the search query changes, reset the current page to 1
    setCurrentPage(1);
  }, [searchQuery]);

  // Pagination logic
  useEffect(() => {
    // After filtering, update the currentUsers state based on the current page
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsersData = filteredUsers.slice(
      indexOfFirstUser,
      indexOfLastUser
    );
    setCurrentUsers(currentUsersData);
  }, [currentPage, filteredUsers]);

  const handleSearch = () => {
    const filteredResults = users.filter((user) =>
      user.accountname.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUsers(filteredResults);
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  //  excel
  const handleExport = () => {
    const excelData = filteredUsers.map((user) => {
      // Format the data for export as needed
      return {
        AccountName: user.accountname,
        Date: user.date_created,
        // ... add other properties
      };
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate binary data from workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Create a Blob with the binary data
    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Download the Excel file
    const filename = "all_shgs.xlsx";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For Internet Explorer
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  // paginations

  const maxPaginationButtons = 5;

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    const visiblePages = Math.min(maxPaginationButtons, totalPages);
    const firstVisiblePage = Math.max(
      currentPage - Math.floor(visiblePages / 2),
      1
    );
    const lastVisiblePage = Math.min(
      firstVisiblePage + visiblePages - 1,
      totalPages
    );

    const buttons = [];

    // Previous button
    if (currentPage > 1) {
      buttons.push(
        <button
          key="prev"
          className="px-3 py-1 mx-2 rounded bg-gray-300"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
      );
    }

    // Page buttons
    for (let page = firstVisiblePage; page <= lastVisiblePage; page++) {
      buttons.push(
        <button
          key={page}
          className={`px-3 py-1 mx-2 rounded ${
            page === currentPage ? "bg-blue-500 text-white" : "bg-gray-300"
          }`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      );
    }

    // Next button
    if (currentPage < totalPages) {
      buttons.push(
        <button
          key="next"
          className="px-3 mx-2 py-1 rounded bg-gray-300"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      );
    }

    return buttons;
  };

  return (
    <>
      <main className="pt-6 md:pt-20 -mt-2">
        <div>
          {filteredUsers === null ? (
            <div>Loading...</div>
          ) : (
            <div className="mx-auto p-2">
              <div className="flex flex-wrap flex-row">
                <div className="flex-shrink max-w-full md:px-4 w-full mb-6">
                  <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                    <div className="flex flex-wrap flex-row -mx-4">
                      <div className="flex-shrink max-w-full px-4 w-full">
                        <div className="w-full mb-6 overflow-x-auto">
                          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                            <div className="md:flex items-center justify-between">
                              <h4 className="dark:text-white font-semibold my-3 md:mr-5">
                                All SHGs
                              </h4>
                              <div>
                                <input
                                  type="text"
                                  placeholder="Search by name..."
                                  value={searchQuery}
                                  onChange={handleChange}
                                  onKeyDown={handleKeyDown}
                                  className="px-4 py-2 border rounded-md w-96 mx-auto mb-4 focus:outline-none focus:border-blue-500"
                                />

                                <button
                                  className="px-4 py-2 rounded bg-blue-500 text-white"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                              </div>
                              {/* <DownloadTableExcel
                                filename="SHGs"
                                sheet="shgs"
                                currentTableRef={shgRef.current}
                              >
                                <button
                                  type="button"
                                  className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                >
                                  Export Excel{" "}
                                  <i className="fas fa-download"></i>
                                </button>
                              </DownloadTableExcel> */}
                              <button
                                onClick={handleExport}
                                type="button"
                                className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                              >
                                Export Excel <i className="fas fa-download"></i>
                              </button>
                            </div>
                          </div>
                          <table
                            className="table-sorter table-bordered w-full text-left text-gray-600 dark:text-gray-400"
                            ref={shgRef}
                          >
                            <thead>
                              <tr className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-40">
                                <th
                                  data-sortable="false"
                                  className="hidden lg:table-cell"
                                >
                                  <input
                                    id="check_all"
                                    type="checkbox"
                                    className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                                  />
                                </th>
                                <th className="hidden lg:table-cell">Image</th>
                                <th className="hidden lg:table-cell">
                                  SHG Name
                                </th>

                                <th className="hidden lg:table-cell">
                                  Date added
                                </th>
                                <th className="hidden lg:table-cell">Status</th>
                                <th
                                  className="hidden lg:table-cell"
                                  data-sortable="false"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                currentUsers.map((data) => (
                                  <tr
                                    key={data.accountname}
                                    className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                                  >
                                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                                      <input
                                        type="checkbox"
                                        className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                                      />
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                      <div className="flex flex-wrap flex-row items-center">
                                        <i className="fas fa-users"></i>
                                      </div>
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                      <Link to={`/sghView/${data.accountname}`}>
                                        {data.accountname}
                                      </Link>
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                      <Link to={`/sghView/${data.accountname}`}>
                                        {data.date_created}
                                      </Link>
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                      <Link to={`/sghView/${data.accountname}`}>
                                        <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
                                          Active
                                        </span>
                                      </Link>
                                    </td>

                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                      <Link to={`/sghView/${data.accountname}`}>
                                        <button
                                          type="button"
                                          className="hidden px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                        >
                                          View{" "}
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr className="flex justify-center  items-center h-40 w-full">
                                  <td className="w-full mx-auto ">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="50"
                                      height="50"
                                      fill="currentColor"
                                      className="bi bi-arrow-repeat animate-spin"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                      <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                    </svg>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {/* Pagination */}
                          <div className="mt-4 flex justify-center">
                            {renderPaginationButtons()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
}

export default Sgh;
