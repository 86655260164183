import React, { useContext } from "react";
import { authContext } from "../../Context/AuthContext";

function UserProfile() {
  const { userName } = useContext(authContext);
  const { userMobile } = useContext(authContext);
  const { userID } = useContext(authContext);
  const { userRole } = useContext(authContext);

  return (
    <>
      <main className="pt-20 -mt-2 md:m-5 m-2">
        <div className="mx-auto p-2">
          <div className="flex-shrink max-w-full px-4 w-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden pb-8">
              <div className="h-40 overflow-hidden relative">
                <img
                  src={require("../../img/login.jpg")}
                  className="w-full"
                  alt="background"
                />
              </div>
              <div className="flex justify-center -mt-10 relative">
                <img
                  src={require("../../img/profile1.png")}
                  className="rounded-full w-24  bg-gray-200 border-solid border-white border-2 -mt-3"
                  alt="profile"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5"></p>
            </div>
            <div className="flex-shrink mx-auto max-w-full px-4 w-full lg:w-2/3 mb-6">
              <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
                <div className="flex-1 p-6 bg-gray-100 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <form>
                    <div className="px-6 py-3 text-xl border-b dark:border-gray-800 font-bold dark:text-gray-300 font-andika">
                      User Profile
                    </div>

                    <div className="p-6 flex-grow">
                      <div className="flex flex-wrap flex-row -mx-4">
                        <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-300">
                            UserName
                          </label>
                          <input
                            disabled
                            type="text"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            id="inputname"
                            required
                            value={userName}
                          />
                        </div>

                        <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-300">
                            ID No
                          </label>
                          <input
                            disabled
                            type="text"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            id="inputEmail4"
                            required
                            value={userID}
                          />
                        </div>

                        <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-300">
                            Mobile number
                          </label>
                          <input
                            disabled
                            type="text"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            id="inputnumber"
                            required
                            value={userMobile}
                          />
                        </div>
                        <div className="form-group flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                          <label className="inline-block mb-2 dark:text-gray-300">
                            Role
                          </label>
                          <input
                            disabled
                            type="text"
                            className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                            id="inputnumber"
                            required
                            value={userRole}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="flex-shrink max-w-full px-4 w-full lg:w-1/3 mb-6">
              <div className="p-6 bg-white dark:bg-gray-800 rounded shadow-lg mb-6">
                <img
                  src={require("../../img/profile1.png")}
                  className="profile-img"
                  alt="profile"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserProfile;
